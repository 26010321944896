import { Menu } from '@headlessui/react';
import classNames from 'classnames';
import type { MouseEventHandler, PropsWithChildren } from 'react';

type Props = {
  children: React.ReactNode;
};

type DropdownComponent = {
  (props: Props): JSX.Element;
  Button: (props: { children: React.ReactNode }) => JSX.Element;
  Items: (
    props: PropsWithChildren<{ static?: boolean } | { unmount?: boolean }>,
  ) => JSX.Element;
  Item: (
    props: PropsWithChildren<{
      className?: string;
      disabled?: boolean;
      onClick?: MouseEventHandler<HTMLButtonElement>;
    }>,
  ) => JSX.Element;
};

export const Dropdown: DropdownComponent = ({ children }: Props) => {
  return (
    <Menu className="relative inline-block text-left" as="div">
      {children}
    </Menu>
  );
};

const DropdownButton: DropdownComponent['Button'] = props => {
  return <Menu.Button {...props} />;
};

const Items: DropdownComponent['Items'] = props => (
  <Menu.Items
    className="absolute right-0 mt-2 w-56 origin-top-right rounded-sm bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
    {...props}
  />
);

const Item: DropdownComponent['Item'] = ({ className, children, onClick }) => (
  <Menu.Item>
    {({ active }: { active: boolean }) => (
      <button
        className={classNames(
          'group flex w-full items-center bg-white px-2 py-2 text-sm text-black',
          {
            ' bg-gray-200': active,
            'text-gray-900': !active,
          },
          className,
        )}
        onClick={onClick}
      >
        {children}
      </button>
    )}
  </Menu.Item>
);

Dropdown.Button = DropdownButton;
Dropdown.Items = Items;
Dropdown.Item = Item;
