/* eslint-disable camelcase */
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { type ReactNode, useRef } from 'react';

import { useOptionalUser } from '@/features/auth/hooks';

export const UserComProvider = ({ children }: { children: ReactNode }) => {
  const user = useOptionalUser();
  const userComSetup = useRef(false);

  if (
    !userComSetup.current &&
    user &&
    typeof window !== 'undefined' &&
    window.UE &&
    window.UE.isReady
  ) {
    window.UE.pageHit({
      apiKey: import.meta.env.VITE_USERCOM_WIDGET_API_KEY,
      name: user.fullName,
      user_id: user.id,
      email: user.email,
      is_pro: user.userIsProPlan ?? 0,
      monitor_limit: user.monitorLimit,
      plan_name: user.activeSubscription?.plan?.name ?? 'Free',
      user_sms_refill: user.smsRefillEnabled === 1,
      // TODO: When API ready
      //   pspCount: user.pspCount,
      monitorCount: user.monitorsCount,
      registration: format(parseISO(user.registeredAt), 'yyyy-MM-dd HH:mm:ss'),
    });

    userComSetup.current = true;
  }

  return <>{children}</>;
};
