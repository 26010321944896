import { useEffect, useMemo, useRef, type ReactNode } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { isNil } from 'remeda';

import type { User } from '@/features/auth/types';

const gtmSetupScriptId = 'ur_gtm_setup_script';
const gtmSourceScriptId = 'ur_gtm_source_script';

export const useGTM = (): {
  dataLayer: Window['dataLayer'];
  generateTransactionId: (userId: number) => string;
} => {
  const generateTransactionId = (userId: number) =>
    `${userId}-${Math.round(new Date().getTime() / 1000)}`; // Transaction ID. Required for purchases and refunds.

  return {
    dataLayer: window.dataLayer || [],
    generateTransactionId, // Transaction ID. Required for purchases and refunds.
  };
};

export const GoogleTagManagerProvider = ({
  children,
}: {
  children: ReactNode;
}) => {
  const gtmSetup = useRef(false);

  const queryClient = useQueryClient();

  const user = queryClient.getQueryData(['auth-user']) as User;

  const observer = useMemo(
    () =>
      new MutationObserver((mutationList, observer) => {
        for (const mutation of mutationList) {
          if (
            mutation.type === 'childList' &&
            // eslint-disable-next-line
            // @ts-ignore
            mutation.addedNodes[0].id! === gtmSetupScriptId
          ) {
            observer.disconnect();
            gtmSetup.current = true;
          }
        }
      }),
    [],
  );

  useEffect(() => {
    // eslint-disable-next-line
    const bodyElement = document.querySelector('body')!;

    const setupScript = document.createElement('script');
    setupScript.id = gtmSetupScriptId;
    setupScript.innerHTML = `    
      window.dataLayer = window.dataLayer || [];
      
      window.dataLayer.push(${JSON.stringify({
        /* @ts-ignore  */
        // eslint-disable-next-line camelcase
        paying_user: !!user.activeSubscription,
        uruid: user.id,
        plan: isNil(user.activeSubscription)
          ? `free_${user.monitorLimit}`
          : `${user.activeSubscription.plan.name.toLowerCase()}_${
              user.monitorLimit
            }`,
      })});

      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${import.meta.env.VITE_GTM_CONTAINER_ID}');
    `;

    const gtmSourceScript = document.createElement('script');
    gtmSourceScript.id =
      gtmSourceScript.innerHTML = `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
    new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
    j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
    'https://users.uptimerobot.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
    })(window,document,'script','dataLayer','${
      import.meta.env.VITE_GTM_CONTAINER_ID
    }');`;
    gtmSourceScript.async = true;

    if (bodyElement) {
      observer.observe(bodyElement, { childList: true });

      bodyElement.appendChild(setupScript);
      bodyElement.appendChild(gtmSourceScript);
    }

    return () => {
      observer.disconnect();
      window.dataLayer = [];

      const gtmSetupScript = document.getElementById(gtmSetupScriptId);
      const gtmSourcepScript = document.getElementById(gtmSourceScriptId);

      if (gtmSetupScript) {
        bodyElement.removeChild(gtmSetupScript);
      }

      if (gtmSourcepScript) {
        bodyElement.removeChild(gtmSourcepScript);
      }
    };
  }, []);

  return <>{children}</>;
};

GoogleTagManagerProvider.displayName = 'GoogleTagManagerProvider';
