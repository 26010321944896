import type { MinimalModalProps } from '@/ui/Modal/modal.component';
import { ErrorModal } from '@/ui';

type ErrorModalProps = MinimalModalProps & {
  title?: string;
  description?: string;
};

export const LegacyPlanErrorModal = ({
  title = 'You have a legacy plan',
  description = 'It is not possible to switch billing period for legacy plans. ' +
    'Contact our support for more information or upgrade to one of our new plans.',
  ...rest
}: ErrorModalProps) => {
  return (
    <ErrorModal
      {...rest}
      title={title}
      description={description}
      onClose={() => false}
      onAction={() => (window.location.href = '/billing/pricing')}
    />
  );
};
