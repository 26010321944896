import { formatISODate } from '../../utils';

import { MAIN_APP_DASHBOARD_URL } from '@/shared/constants';
import { ErrorModal, type ModalProps } from '@/ui';

type Props = {
  availableDate: string;
} & ModalProps<'div'>;

export const SubscriptionChangeNotPossibleModal = ({
  availableDate,
  onClose,
  ...rest
}: Props) => {
  const handleClose = () => {
    onClose(false);
    window.location.href = MAIN_APP_DASHBOARD_URL;
  };

  return (
    <ErrorModal
      onClose={handleClose}
      title="Subscription change is not possible right now"
      description={
        'Downgrades and billing period changes are not possible. ' +
        `You can change your subscription after ${formatISODate(
          availableDate,
        )}. If you have any questions, contact our support please.`
      }
      {...rest}
    />
  );
};
