import classNames from 'classnames';

type Props = {
  hideContentBelow?: boolean;
  inline?: boolean;
};

export const GlobalLoader = ({ hideContentBelow = false, inline }: Props) => (
  <div
    className={classNames(
      'top-0 left-0 z-50 flex h-full w-full items-center justify-center',
      {
        'bg-blue-dark/80': !hideContentBelow,
        'bg-blue-dark': hideContentBelow,
        fixed: !inline,
        absolute: inline,
      },
    )}
    aria-live="assertive"
    aria-label="Do not refresh the page"
  >
    <label className="sr-only">Active loading indicator</label>
    <div className="relative flex h-32 w-32">
      <div className="absolute inline-flex h-full w-full animate-ping rounded-full bg-primary-main opacity-75 motion-reduce:animate-none" />
      <div className="inline-flex h-32 w-32 rounded-full bg-primary-main" />
    </div>
  </div>
);
