import type { StripeError } from '@stripe/stripe-js';
import type { AxiosResponse } from 'axios';
import {
  add,
  differenceInHours,
  format,
  getUnixTime,
  isBefore,
  isWithinInterval,
  parseISO,
  subHours,
} from 'date-fns';
import { isNil, pipe, sortBy, uniqBy } from 'remeda';

import { LEGACY_PRO_PLAN_ID, NEW_PRICING_RELEASE_TIMESTAMP } from './constants';
import type {
  AddonProduct,
  BillingPeriod,
  GetProductsData,
  GetProductsQuerySelectorResult,
  PlanProduct,
  Product,
  VolumePricedProduct,
} from './types';

import {
  SubscriptionPaymentPeriod,
  type CreditCardUser,
  type Plan,
  type SubscribedUser,
  type SubscriptionFeature,
  type User,
} from '@/features/auth/types';
import type { RequiredProperties } from '@/shared/utils/types.type';

export const parsePlanFromProductQuery = (
  products: GetProductsData,
  monitorCount: number,
) => {
  return products.find(
    product => !!product.plan && product.value === monitorCount,
  ) as PlanProduct;
};

export const getPlanDescription = (selectedPlanProduct: PlanProduct) => {
  let description = selectedPlanProduct.value.toString() + ' monitors';

  if (selectedPlanProduct.plan.featureDefinition.limits.subusers) {
    description += `, ${selectedPlanProduct.plan.featureDefinition.limits.subusers} login seat(s)`;
  }

  if (
    selectedPlanProduct.plan.featureDefinition.limits['subusers-notif-only']
  ) {
    description += `, ${selectedPlanProduct.plan.featureDefinition.limits['subusers-notif-only']} notify-only seat(s)`;
  }

  if (selectedPlanProduct.extraCredits) {
    description += `, ${selectedPlanProduct.extraCredits} extra credits`;
  }

  description += ' incl.';
  return description;
};

export const getProductPriceForPeriod = (
  billingPeriod: 'monthly' | 'annually',
  product: Product,
) => {
  return billingPeriod === 'annually'
    ? product.yearlyPrice
    : product.monthlyPrice;
};

export const userHasCreditCard = (user: User): user is CreditCardUser =>
  !isNil(user.creditCard);

export const parseLoginSeatAddonsFromProductQuery = (
  products: GetProductsData,
) => {
  return products.find(
    product => product.type === 'addon' && product.addon?.name === 'Login seat',
  ) as AddonProduct;
};

export const getPlanVariants = (data: GetProductsData, planId: number | null) =>
  data.filter(
    product => product.plan && product.plan.id === planId,
  ) as PlanProduct[];

export const billingPeriodToUnit = (billingPeriod: 'monthly' | 'annually') =>
  billingPeriod === 'annually' ? 'year' : 'month';

export const subscriptionPaymentPeriodToBillingPeriod = (
  subscriptionPaymentPeriod: SubscriptionPaymentPeriod,
) =>
  subscriptionPaymentPeriod === SubscriptionPaymentPeriod.Monthly
    ? 'monthly'
    : 'annually';

export const isStripeCardError = (
  error: unknown,
): error is RequiredProperties<StripeError, 'message'> => {
  return (
    /* eslint-disable-next-line */
    // @ts-ignore https://devblogs.microsoft.com/typescript/announcing-typescript-4-9-beta/#unlisted-property-narrowing-with-the-in-operator
    typeof error === 'object' && error !== null && error.type === 'card_error'
  );
};

export const userHasLegacyFreePlan = (user: User) => {
  return (
    isNil(user.activeSubscription) &&
    isBefore(
      pipe(user.registeredAt, parseISO, getUnixTime),
      NEW_PRICING_RELEASE_TIMESTAMP,
    )
  );
};

export const userHasLegacyPlan = (user: User) => {
  return user.activeSubscription?.plan?.id === LEGACY_PRO_PLAN_ID;
};

export const userHasLegacyPlanOrLegacyFreePlan = (user: User) => {
  return (
    user.activeSubscription?.plan?.id === LEGACY_PRO_PLAN_ID ||
    userHasLegacyFreePlan(user)
  );
};

export const getProductsQuerySelector = (
  response: AxiosResponse<GetProductsData>,
): GetProductsQuerySelectorResult => {
  return {
    planProducts: response.data.filter<PlanProduct>(
      (product): product is PlanProduct => !isNil(product.plan),
    ),
    ...response,
  };
};

export const getProductQueryCheckoutSelector = (
  response: AxiosResponse<GetProductsData>,
): GetProductsQuerySelectorResult => {
  const filteredData = response.data.filter(product => product.name !== 'Free');

  return getProductsQuerySelector({ ...response, data: filteredData });
};

export const calculateDiscountedAmount = (price: number, discount: number) => {
  const discountAmount = (price * discount) / 100;
  const discountedPriceAmount = price - discountAmount;

  return discountedPriceAmount;
};

export const getDefaultBillingName = (user: User) => {
  if (!isNil(user.billing.name)) {
    return user.billing.name;
  }

  if (!isNil(user.fullName)) {
    return user.fullName;
  }

  return undefined;
};

export const getBestOfferedPrice = (
  product: VolumePricedProduct,
  quantity: number,
  billingPeriod: 'monthly' | 'annually',
) => {
  const volumePricesForPeriod =
    billingPeriod === 'monthly'
      ? product.monthlyVolumePrice
      : product.yearlyVolumePrice;

  const volumePrice = volumePricesForPeriod.find(volumePrice => {
    if (quantity > 0) {
      return quantity <= (volumePrice.unitMax ?? Infinity);
    }

    return quantity === 0 && isNil(volumePrice.unitMax);
  });

  if (isNil(volumePrice)) {
    /* NOTE: fallback to best offer */
    return billingPeriod === 'monthly' ? 15 : 120;
  }

  if (billingPeriod === 'monthly') {
    return volumePrice.price;
  }

  return volumePrice.price;
};

export const isVolumePricedProduct = (
  product: Product,
): product is VolumePricedProduct => {
  if (
    isNil(product.monthlyPrice) &&
    isNil(product.yearlyPrice) &&
    isNil(product.oncePrice) &&
    !isNil(product.yearlyVolumePrice) &&
    !isNil(product.monthlyVolumePrice)
  ) {
    return true;
  }

  return false;
};

export const getVolumeBasedProductVariants = (product: VolumePricedProduct) => {
  const monthlyVolumes = product.monthlyVolumePrice.map(volume => ({
    value: volume.unitMin,
    price: volume.price,
  }));

  const yearlyVolumes = product.yearlyVolumePrice.map(volume => ({
    value: volume.unitMin,
    price: volume.price,
  }));

  return pipe(
    monthlyVolumes.concat(yearlyVolumes),
    uniqBy(volume => volume.value),
    sortBy(volume => volume.value),
  );
};

const DATE_FORMAT_DEFAULT = 'LLLL do, yyyy';

export const getNextBillingDate = (
  billingPeriod: BillingPeriod,
  dateFormat = DATE_FORMAT_DEFAULT,
) =>
  format(
    add(new Date(), {
      years: billingPeriod === 'annually' ? 1 : 0,
      months: billingPeriod === 'monthly' ? 1 : 0,
    }),
    dateFormat,
  );

export const formatISODate = (date: string, dateFormat = DATE_FORMAT_DEFAULT) =>
  format(parseISO(date), dateFormat);

export const isYearly = (billingPeriod: BillingPeriod) =>
  billingPeriod === 'annually';

export const isMonthly = (billingPeriod: BillingPeriod) =>
  billingPeriod === 'monthly';

export const getPlanFeatureDefinition = (
  plan: Plan,
  feature: SubscriptionFeature,
) => plan.featureDefinition.features.includes(feature);

export const canDowngradeWithoutRestrictions = (user: User) => {
  if (isNil(user.activeSubscription)) {
    return true;
  }

  const { status, expirationDate: expDateString } = user.activeSubscription;
  const expirationDate = parseISO(expDateString);

  const isResubscribing = status === 'terminated';
  const isExpirationWithin24h = isWithinInterval(new Date(), {
    start: subHours(expirationDate, 24),
    end: expirationDate,
  });

  return !isResubscribing && !isExpirationWithin24h;
};

export const canUpdatePlan = (user: User, maxMonitors: number): boolean => {
  const usedMonitorsCount = user.monitorsCount;

  return usedMonitorsCount <= maxMonitors;
};

export const canUpdateAddons = (
  user: User,
  maxLoginSeats: number,
  maxNotifySeats: number,
): boolean => {
  const usedNotifySeatsCount = user.organization?.alertContactCount ?? 0;
  const usedLoginSeatsCount =
    (user.organization?.memberCount ?? 0) +
    (user.organization?.pendingInvitationCount ?? 0);

  return (
    usedNotifySeatsCount <= maxNotifySeats &&
    usedLoginSeatsCount <= maxLoginSeats
  );
};

/**
 *
 * @param expirationDate {string} subscription expiration date in ISO format
 * @returns {boolean} true if expiration date is within 24 hours from now
 */
export const getIsWithinInstantUpdateThreshold = (expirationDate: string) => {
  const diffInHours = differenceInHours(parseISO(expirationDate), new Date());

  return diffInHours <= 24 && diffInHours >= 0;
};

// /**
//  *
//  * @param planValue {number} value of a plan - monitor limit
//  * @param currentMonitorLimit {number} current monitor limit based on their current plan
//  * @param monitorCount {number} number of monitors created
//  * @param hasLegacyPlan
//  */
// export const isMonitorPlanVariantDisabled = (
//   planValue: number,
//   currentMonitorLimit: number,
//   monitorCount: number,
//   hasLegacyPlan: boolean,
//   isWithinInstantUpdateThreshold: boolean,
// ) => {
//   const isCurrentPlan = !hasLegacyPlan && planValue === currentMonitorLimit;

//   if (isCurrentPlan) {
//     /* Cannot choose the current plan */
//     return true;
//   }

//   if (!isWithinInstantUpdateThreshold) {
//     /* NOTE: if we are not within instant update threshold it means we can schedule any change */
//     return false;
//   }

//   console.log(planValue, monitorCount);

//   return planValue < monitorCount;
// };

// export const canUserUpdateSubscription = (
//   isFormValid: boolean,
//   subscriptionProvider: 'stripe' | 'paypal' | 'IAP',
//   isWithinInstantUpdateThreshold: boolean,
//   extraSeatActionRequired:
//     | 'legacy-plan'
//     | 'plan-without-seats'
//     | 'not-enough-extra-seats'
//     | undefined,
//   minNotifySeatsToBuy: number,
// ) => {
//   if (!isFormValid || subscriptionProvider === 'IAP') {
//     return false;
//   }

//   if (
//     isWithinInstantUpdateThreshold &&
//     (extraSeatActionRequired === 'plan-without-seats' ||
//       minNotifySeatsToBuy > 0)
//   ) {
//     return false;
//   }

//   return true;
// };

export const canOfferExtraCredits = (
  user: SubscribedUser,
  isUpgrade: boolean,
) => {
  if (!isUpgrade) {
    return false;
  }

  if (user.activeSubscription.provider == 'paypal') {
    return false;
  }

  return true;
};
