import { useQueryClient } from '@tanstack/react-query';
import { lazy, useMemo } from 'react';
import {
  Navigate,
  RouterProvider,
  createBrowserRouter,
} from 'react-router-dom';

import { RequireSubscription } from './app/shared/components/require-subscription.component';
import { useSubscribedUser } from './features/auth/hooks';
import { extraCreditsRouteLoader } from './pages/ExtraCredits';
import { billingPeriodRouteLoader } from './pages/billing-period';
import { cancelSubscriptionChangeRouteLoader } from './pages/cancel-subscription-change';
import { pricingRouteLoader } from './pages/pricing/pricing-route.loader';
import { subscribeRouteLoader } from './pages/subscribe-page';
import { updateAddonsRouteLoader } from './pages/update-addons';
import { updateSubscriptionRouteLoader } from './pages/update-subscription';
import { RouteErrorBoundary } from './shared/components';
import { useLoaderActions, useLoaderStore } from './stores/loader.store';
import { GlobalLoader } from './ui';

import { ModalManager } from '@/shared/components/ModalManager/modal-manager.component';
import { RequireAuth } from '@/shared/components/RequireAuth/require-auth.component';

const Pricing = lazy(() =>
  import('./pages/pricing').then(mod => ({
    default: mod.Pricing,
  })),
);
const SubscribePage = lazy(() =>
  import('./pages/subscribe-page').then(mod => ({
    default: mod.SubscribePage,
  })),
);
const BillingPeriodPage = lazy(() =>
  import('./pages/billing-period').then(mod => ({
    default: mod.BillingPeriodPage,
  })),
);
const ExtraCreditsPage = lazy(() =>
  import('./pages/ExtraCredits').then(mod => ({
    default: mod.ExtraCreditsPage,
  })),
);
const UpdateSubscriptionPage = lazy(() =>
  import('./pages/update-subscription').then(mod => ({
    default: mod.UpdateSubscriptionPage,
  })),
);
const UpdateLegacySubscriptionPage = lazy(() =>
  import('./pages/update-subscription').then(mod => ({
    default: mod.UpdateLegacySubscriptionPage,
  })),
);
const UpdateAddonsPage = lazy(() =>
  import('./pages/update-addons').then(mod => ({
    default: mod.UpdateAddonsPage,
  })),
);

const CancelSubscriptionChangePage = lazy(() =>
  import('./pages/cancel-subscription-change').then(mod => ({
    default: mod.CancelSubscriptionChangePage,
  })),
);

export function App() {
  const queryClient = useQueryClient();
  const globalLoaderActions = useLoaderActions();
  const isGlobalLoaderActive = useLoaderStore(state => state.active);
  const subscribedUser = useSubscribedUser();

  const router = useMemo(
    () =>
      createBrowserRouter([
        {
          path: '/',
          element: <Navigate to="/billing/pricing" />,
        },
        {
          path: '/billing',
          errorElement: <RouteErrorBoundary />,
          children: [
            {
              path: 'pricing',
              index: true,
              element: (
                <RequireAuth>
                  <Pricing />
                </RequireAuth>
              ),
              loader: pricingRouteLoader(queryClient, globalLoaderActions),
            },
            {
              path: 'checkout',
              children: [
                {
                  path: 'subscription',
                  element: (
                    <RequireAuth>
                      <SubscribePage />
                    </RequireAuth>
                  ),
                  loader: subscribeRouteLoader(
                    queryClient,
                    globalLoaderActions,
                    subscribedUser,
                  ),
                },
                {
                  path: 'billing-period',
                  element: (
                    <RequireAuth>
                      <RequireSubscription noLegacyUser={false}>
                        <BillingPeriodPage />
                      </RequireSubscription>
                    </RequireAuth>
                  ),
                  loader: billingPeriodRouteLoader(
                    queryClient,
                    globalLoaderActions,
                    subscribedUser,
                  ),
                },
                {
                  path: 'extra-credits',
                  element: (
                    <RequireAuth>
                      <ExtraCreditsPage />
                    </RequireAuth>
                  ),
                  loader: extraCreditsRouteLoader(
                    queryClient,
                    globalLoaderActions,
                    subscribedUser,
                  ),
                },
                {
                  path: 'subscription-change',
                  element: (
                    <RequireAuth>
                      <RequireSubscription>
                        <UpdateSubscriptionPage />
                      </RequireSubscription>
                    </RequireAuth>
                  ),
                  loader: updateSubscriptionRouteLoader(
                    queryClient,
                    globalLoaderActions,
                    subscribedUser,
                  ),
                },
                {
                  path: 'subscription-change-legacy',
                  element: (
                    <RequireAuth>
                      <RequireSubscription noLegacyUser={false}>
                        <UpdateLegacySubscriptionPage />
                      </RequireSubscription>
                    </RequireAuth>
                  ),
                  loader: updateSubscriptionRouteLoader(
                    queryClient,
                    globalLoaderActions,
                    subscribedUser,
                  ),
                },
                {
                  path: 'extra-seats',
                  element: <Navigate to="/billing/checkout/seats-change" />,
                },
                {
                  path: 'notify-seats',
                  element: <Navigate to="/billing/checkout/seats-change" />,
                },
                {
                  path: 'seats-change',
                  element: (
                    <RequireAuth>
                      <RequireSubscription>
                        <UpdateAddonsPage />
                      </RequireSubscription>
                    </RequireAuth>
                  ),
                  loader: updateAddonsRouteLoader(
                    queryClient,
                    globalLoaderActions,
                    subscribedUser,
                  ),
                },
                {
                  path: 'cancel-subscription-change',
                  element: (
                    <RequireAuth>
                      <RequireSubscription noLegacyUser={false}>
                        <CancelSubscriptionChangePage />
                      </RequireSubscription>
                    </RequireAuth>
                  ),
                  loader: cancelSubscriptionChangeRouteLoader(
                    queryClient,
                    globalLoaderActions,
                    subscribedUser,
                  ),
                },
              ],
            },
          ],
        },
        { path: '*', element: <Navigate to="/billing/pricing" /> },
      ]),
    [queryClient, globalLoaderActions, subscribedUser],
  );

  return (
    <div className="App">
      {isGlobalLoaderActive && <GlobalLoader hideContentBelow />}

      <RouterProvider router={router} />

      <ModalManager />
    </div>
  );
}
