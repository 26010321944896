import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const CCDiscoverIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 40.667"
    ref={ref}
    {...props}
  >
    <radialGradient
      xmlns="http://www.w3.org/2000/svg"
      id="a"
      cx="291.9609"
      cy="118.4253"
      gradientTransform="matrix(1 0 0 -1 -259.1836 138.7607)"
      gradientUnits="userSpaceOnUse"
      r="3.1535"
    >
      <stop offset=".0056" stopColor="#ffeee0" />
      <stop offset=".1096" stopColor="#fee9d7" />
      <stop offset=".2793" stopColor="#fddbbf" />
      <stop offset=".4935" stopColor="#fbc597" />
      <stop offset=".744" stopColor="#f8a560" />
      <stop offset="1" stopColor="#f48120" />
    </radialGradient>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m2.133 39.833c-.9 0-1.633-.732-1.633-1.633v-35.733c0-.901.732-1.634 1.633-1.634h59.734c.9 0 1.633.733 1.633 1.634v35.734c0 .9-.732 1.633-1.633 1.633h-59.734z"
      fill="#f9f9f9"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m61.867 1.333c.625 0 1.133.509 1.133 1.134v35.734c0 .625-.508 1.133-1.133 1.133h-59.734c-.625-.001-1.133-.508-1.133-1.133v-35.734c0-.625.508-1.134 1.133-1.134zm0-1h-59.734c-1.177 0-2.133.955-2.133 2.134v35.734c0 1.176.956 2.133 2.133 2.133h59.734c1.177 0 2.133-.955 2.133-2.133v-35.734c0-1.179-.956-2.134-2.133-2.134z"
      fill="#c6c6c5"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m17.592 40.333h44.275c1.177 0 2.133-.957 2.133-2.135v-14.976c-4.249 2.695-20.27 11.981-46.408 17.111z"
      fill="#f58220"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m32.782 17.218c-1.764 0-3.194 1.377-3.194 3.077 0 1.808 1.369 3.158 3.194 3.158 1.78 0 3.185-1.367 3.185-3.123 0-1.743-1.397-3.112-3.185-3.112z"
      fill="url(#a)"
    />
    <g xmlns="http://www.w3.org/2000/svg" fill="#001722">
      <path d="m14.65 17.33h-1.716v5.989h1.708c.906 0 1.562-.215 2.137-.69.682-.566 1.086-1.42 1.086-2.3 0-1.77-1.322-2.999-3.215-2.999zm1.366 4.498c-.369.334-.844.478-1.6.478h-.314v-3.96h.314c.755 0 1.212.135 1.6.484.404.359.645.916.645 1.489-.001.575-.241 1.151-.645 1.509z" />
      <path d="m18.404 17.33h1.167v5.989h-1.167z" />
      <path d="m22.428 19.626c-.702-.259-.907-.431-.907-.753 0-.377.366-.664.871-.664.35 0 .638.144.943.483l.61-.797c-.503-.441-1.104-.666-1.76-.666-1.059 0-1.868.736-1.868 1.715 0 .827.377 1.249 1.473 1.644.458.161.691.27.808.342.234.153.351.368.351.618 0 .486-.387.845-.908.845-.557 0-1.005-.276-1.274-.798l-.753.729c.538.789 1.184 1.141 2.074 1.141 1.212 0 2.065-.811 2.065-1.967-.002-.954-.396-1.385-1.725-1.872z" />
      <path d="m24.519 20.328c0 1.761 1.384 3.126 3.162 3.126.503 0 .934-.1 1.465-.351v-1.375c-.468.468-.881.655-1.411.655-1.176 0-2.012-.854-2.012-2.066 0-1.147.862-2.056 1.958-2.056.556 0 .979.198 1.465.674v-1.374c-.512-.261-.935-.367-1.438-.367-1.77.001-3.189 1.393-3.189 3.134z" />
      <path d="m38.609 21.353-1.597-4.023h-1.276l2.541 6.142h.629l2.587-6.142h-1.264z" />
      <path d="m42.023 23.319h3.315v-1.015h-2.147v-1.617h2.065v-1.014h-2.065v-1.329h2.147v-1.014h-3.315z" />
      <path d="m49.961 19.097c0-1.122-.771-1.768-2.119-1.768h-1.732v5.989h1.168v-2.406h.152l1.616 2.406h1.437l-1.887-2.523c.881-.178 1.365-.781 1.365-1.698zm-2.344.989h-.34v-1.815h.357c.729 0 1.124.308 1.124.89.002.601-.395.925-1.141.925z" />
    </g>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m50.785 17.776c0-.104-.07-.162-.198-.162h-.171v.528h.125v-.206l.146.206h.158l-.178-.219c.077-.019.118-.073.118-.147zm-.221.072h-.023v-.137h.025c.062 0 .093.021.093.066 0 .047-.032.071-.095.071z"
      fill="#231f20"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m50.611 17.416c-.256 0-.461.205-.461.461s.205.461.461.461c.25 0 .455-.208.455-.461s-.205-.461-.455-.461zm-.002.838c-.202 0-.367-.167-.367-.377 0-.211.164-.378.367-.378.199 0 .363.17.363.378s-.161.377-.363.377z"
      fill="#231f20"
    />
  </svg>
));

CCDiscoverIcon.displayName = 'CCDiscoverIcon';
