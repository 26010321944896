export const objectToUrlSearchParams = (
  obj: Record<string, string | number | Date | null | undefined>,
  options: {
    removeFalsy?: boolean;
  } = {
    removeFalsy: true,
  },
): URLSearchParams => {
  const params = new URLSearchParams();

  (Object.keys(obj) as Array<keyof typeof obj>).forEach(key => {
    if ((obj[key] !== null && obj[key] !== undefined) || !options.removeFalsy) {
      params.append(key, obj[key]!.toString());
    }
  });

  return params;
};
