import classNames from 'classnames';
import { type ComponentPropsWithoutRef, type ElementType } from 'react';

type ButtonSize = 'small' | 'medium' | 'large';
type ButtonColor = 'primary' | 'default';

export type Props<Component extends ElementType = 'button'> =
  ComponentPropsWithoutRef<Component> & {
    as?: Component;
    size?: ButtonSize;
    fullWidth?: boolean;
    color?: ButtonColor;
  };

export type ButtonProps<Component extends ElementType> = Props<Component> &
  Omit<ComponentPropsWithoutRef<Component>, keyof Props<Component>>;

const sizesForButtonSize: Record<ButtonSize, string> = {
  small: 'py-1.5 px-4 text-base',
  medium: 'py-2.5 px-6',
  large: 'py-3 px-8 text-lg',
};

const buttonColorTokens = ({
  color,
  disabled,
}: {
  color: ButtonColor;
  disabled?: boolean;
}) => {
  if (disabled) {
    return 'bg-gray-400 text-white';
  }

  const tokens: Record<ButtonColor, string> = {
    primary:
      'bg-primary-main hover:bg-primary-darker text-white active:bg-primary-dark',
    default: 'bg-grey-main text-white active:bg-grey-dark',
  };

  return tokens[color];
};

export const Button = <Component extends ElementType = 'button'>({
  as,
  className,
  children,
  color = 'primary',
  size = 'medium',
  ...props
}: ButtonProps<Component>) => {
  const Component = as || 'button';

  return (
    <Component
      className={classNames(
        'enabled: inline-flex w-full min-w-[50px] justify-center rounded-full font-bold text-white transition duration-100 focus:outline-none enabled:shadow-button enabled:hover:shadow-button-hover',
        {
          [sizesForButtonSize[size]]: true,
          [buttonColorTokens({ color, disabled: props.disabled })]: true,
          [className]: !!className,
        },
      )}
      {...props}
    >
      {children}
    </Component>
  );
};
