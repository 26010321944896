import { isNil } from 'remeda';

import { useGetProducts } from '../../api/get-products.query';

import { Button, Modal, type ModalProps } from '@/ui';

interface Props {
  title?: string;
  code: string | undefined;
  discount: number | undefined;
  applicableProducts: string[] | undefined;
  discountType: 'once' | 'lifetime' | undefined;
  onClose: ModalProps['onClose'];
}

export const PaymentCouponSuccessModal = ({
  title = 'Coupon added',
  discount,
  applicableProducts,
  discountType: couponType,
  code: couponCode,
  onClose,
}: Props) => {
  const productsQuery = useGetProducts({ refetchOnMount: false });

  const handleClose = () => {
    onClose(false);
  };

  const getCouponTypeText = () => {
    if (isNil(couponType)) {
      return 'dicount';
    }

    if (couponType === 'lifetime') {
      return 'LIFETIME discount';
    }

    return 'discount for the first payment';
  };

  const getCouponApplicableProductNames = () => {
    if (applicableProducts?.length === 0) {
      return 'all products';
    }

    return productsQuery.data?.data
      .filter(product => applicableProducts?.includes(product.id.toString()))
      .map(product => product.name)
      .join(', ')
      .trim();
  };

  return (
    <Modal onClose={handleClose} size="lg">
      <div className="flex flex-col items-center justify-center text-lg">
        <img
          alt="celebrate"
          src="/assets/images/celebrate-icon.png"
          className="mb-8 h-40 w-40"
        />
        <p className="mb-8 text-4xl font-bold">{title}</p>
        <p className="mb-8 text-4xl">
          {discount}% {getCouponTypeText()}
        </p>
        <p className="mb-6">
          on the following products: <br />
          {getCouponApplicableProductNames()}
        </p>
        <p>
          Coupon with code: &ldquo;{couponCode}&ldquo; was successfully applied.
        </p>
        <Button
          type="button"
          color="primary"
          className="mt-12 w-[222px]"
          onClick={handleClose}
        >
          Got it
        </Button>
      </div>
    </Modal>
  );
};
