import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const RingingBellIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    viewBox="0 0 132 132"
    ref={ref}
    {...props}
  >
    <g>
      <path
        fill="currentColor"
        d="M105.1,64.3v-8.1c0-16.8-11-31.9-26.9-37.1v-0.5c0-6.7-5.5-12.2-12.2-12.2c-6.7,0-12.2,5.5-12.2,12.2v0.5   c-15.9,5.2-26.9,20.4-26.9,37.1v8.1c0,17.5-4.1,23.5-7,28c-1.8,2.7-3.7,5.5-3.7,9.7c0,7.8,11.3,12.6,33.5,14.3   c2.6,6.8,9,11.4,16.4,11.4s13.8-4.5,16.4-11.4c22.2-1.7,33.5-6.5,33.5-14.3c0-4.1-1.9-6.9-3.7-9.7   C109.2,87.8,105.1,81.8,105.1,64.3z M105.3,96.9c1.7,2.6,2.3,3.6,2.3,5c-0.8,2.3-16.2,6.7-41.6,6.7c-25.4,0-40.8-4.4-41.6-6.6   c0-1.5,0.6-2.5,2.3-5.1c3.4-5,8.4-12.6,8.4-32.6v-8.1c0-17,13.8-30.8,30.8-30.8s30.8,13.8,30.8,30.8v8.1   C96.8,84.3,101.9,91.9,105.3,96.9z M66,14.7c1.7,0,3.2,1.1,3.7,2.7c-2.7-0.3-4.8-0.3-7.4,0C62.8,15.8,64.3,14.7,66,14.7z M66,119.3   c-2.4,0-4.7-0.9-6.4-2.6c2.9,0.1,5.1,0.1,6.4,0.1c1.3,0,3.5,0,6.4-0.1C70.7,118.4,68.4,119.3,66,119.3z"
      />
      <path
        fill="currentColor"
        d="M128.7,47c-0.6-1.7-2.1-2.8-3.9-2.8c-0.4,0-0.9,0.1-1.3,0.2c-2.2,0.7-3.3,3.1-2.6,5.2   c1.9,5.7,2.9,11.5,2.9,17.4c0,5.9-1,11.7-2.9,17.4c-0.7,2.2,0.5,4.5,2.6,5.2c0.4,0.1,0.9,0.2,1.3,0.2c1.8,0,3.4-1.1,3.9-2.8   c2.2-6.5,3.3-13.2,3.3-20C132,60.2,130.9,53.5,128.7,47z"
      />
      <path
        fill="currentColor"
        d="M118.5,50.4c-0.7-2.1-3-3.3-5.3-2.6c-2.2,0.7-3.3,3.1-2.6,5.2c1.5,4.6,2.3,9.3,2.3,14c0,4.7-0.8,9.4-2.3,14   c-0.7,2.2,0.5,4.5,2.6,5.3c0.4,0.1,0.9,0.2,1.3,0.2c1.8,0,3.4-1.1,3.9-2.8c1.8-5.4,2.7-11,2.7-16.6   C121.2,61.4,120.3,55.8,118.5,50.4z"
      />
      <path
        fill="currentColor"
        d="M8.3,67c0-5.9,1-11.7,2.9-17.4c0.7-2.2-0.5-4.5-2.6-5.3c-0.4-0.1-0.8-0.2-1.3-0.2c-1.8,0-3.4,1.1-4,2.8   C1.1,53.5,0,60.2,0,67c0,6.8,1.1,13.5,3.3,20c0.6,1.7,2.2,2.8,3.9,2.8c0.4,0,0.9-0.1,1.3-0.2c2.2-0.7,3.3-3.1,2.6-5.2   C9.3,78.7,8.3,72.9,8.3,67z"
      />
      <path
        fill="currentColor"
        d="M18.7,86.2c2.2-0.7,3.3-3.1,2.6-5.2c-1.5-4.6-2.3-9.3-2.3-14c0-4.7,0.8-9.4,2.3-14c0.7-2.2-0.5-4.5-2.6-5.3   c-0.4-0.1-0.9-0.2-1.3-0.2c-1.8,0-3.4,1.1-3.9,2.8c-1.8,5.4-2.7,11-2.7,16.6c0,5.6,0.9,11.2,2.7,16.6c0.6,1.7,2.2,2.8,3.9,2.8   C17.8,86.5,18.3,86.4,18.7,86.2z"
      />
    </g>
  </svg>
));

RingingBellIcon.displayName = 'RingingBellIcon';
