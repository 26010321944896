import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const CCMaestroICon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 40.7"
    ref={ref}
    {...props}
  >
    <path
      d="m2.1 39.8c-.9 0-1.6-.7-1.6-1.6v-35.7c0-.9.7-1.6 1.6-1.6h59.7c.9 0 1.6.7 1.6 1.6v35.7c0 .9-.7 1.6-1.6 1.6z"
      fill="#f9f9f9"
    />
    <path
      d="m61.9 1.3c.6 0 1.1.5 1.1 1.1v35.7c0 .6-.5 1.1-1.1 1.1h-59.8c-.6 0-1.1-.5-1.1-1.1v-35.6c0-.6.5-1.1 1.1-1.1h59.8m0-1.1h-59.8c-1.1 0-2.1 1-2.1 2.2v35.7c0 1.2 1 2.1 2.1 2.1h59.7c1.2 0 2.1-1 2.1-2.1v-35.7c.1-1.2-.9-2.2-2-2.2z"
      fill="#c6c6c5"
    />
    <path
      d="m39.2 31.9h.6l.6-.1h.5l.6-.1.5-.2.6-.1.5-.2.5-.2.5-.2.5-.3.5-.3.5-.3.4-.3.4-.3.5-.4.4-.3.3-.4.4-.5.3-.4.3-.4.3-.5.3-.5.3-.5.2-.5.2-.5.2-.5.1-.6.2-.5.1-.6v-.5l.1-.6v-.6-.6l-.1-.6v-.5l-.1-.6-.2-.5-.1-.6-.2-.5-.2-.5-.2-.5-.3-.5-.3-.5-.3-.4-.3-.5-.3-.4-.4-.4-.3-.4-.4-.4-.5-.4-.4-.3-.4-.3-.5-.3-.5-.3-.5-.2-.5-.3-.5-.2-.5-.1-.6-.2-.5-.1-.6-.1-.5-.1h-.6-.6-.6-.6l-.5.1-.6.1-.5.1-.6.2-.5.1-.5.2-.5.3-.5.2-.5.3-.5.3-.4.3-.4.3-.4.4-.4.4-.4.4-.4.4-.3.4-.3.5-.3.4-.3.5-.2.5-.3.5-.2.5-.2.5-.1.6-.1.5-.1.6-.1.5v.6l-.1.6.1.6v.6l.1.5.1.6.1.5.1.6.2.5.2.5.3.5.2.5.3.5.3.5.3.4.3.4.4.5.4.4.4.3.4.4.4.3.4.3.5.3.5.3.5.3.5.2.5.2.5.2.6.1.5.2.6.1h.5l.6.1z"
      fill="#ec1c2e"
    />
    <path
      d="m24.1 31.9h.6l.6-.1h.6l.5-.1.6-.2.5-.1.5-.2.6-.2.5-.2.4-.3.5-.3.5-.3.4-.3.5-.3.4-.4.4-.3.3-.4.4-.5.3-.4.4-.4.2-.5.3-.5.3-.5.2-.5.2-.5.2-.5.1-.6.2-.5.1-.6v-.5l.1-.6v-.6-.6l-.1-.6v-.5l-.1-.6-.2-.5-.1-.6-.2-.5-.2-.5-.2-.5-.3-.5-.3-.5-.2-.4-.4-.5-.3-.4-.4-.4-.3-.4-.4-.4-.4-.4-.5-.3-.4-.3-.5-.3-.5-.3-.4-.2-.5-.3-.6-.2-.5-.1-.5-.2-.6-.1-.5-.1-.6-.1h-.6-.6-.6-.5l-.6.1-.6.1-.5.1-.6.2-.5.1-.5.2-.5.3-.5.2-.5.3-.4.3-.5.3-.4.3-.4.4-.4.4-.4.4-.4.4-.3.4-.3.5-.3.4-.3.5-.2.5-.3.5-.2.5-.1.5-.2.6-.1.5-.1.6-.1.5v.6l-.1.6.1.6v.6l.1.5.1.6.1.5.2.6.1.5.2.5.3.5.2.5.3.5.3.5.3.4.3.4.4.5.4.4.4.3.4.4.4.3.5.3.4.3.5.3.5.3.5.2.5.2.5.2.6.1.5.2.6.1h.6l.5.1z"
      fill="#0084c4"
    />
    <g fill="#ec1c2e">
      <path d="m30.3 13.5h5.7v-.7h-5.2z" />
      <path d="m29.4 14.8h6.6v-.6h-6.2z" />
      <path d="m28.7 16.2h7.3v-.6h-7z" />
      <path d="m28.2 17.5h7.8v-.6h-7.6z" />
      <path d="m28 18.9h8v-.6h-7.9z" />
      <path d="m27.9 21.5h8.1v-.6h-8.2z" />
      <path d="m28.1 22.9h7.9v-.6h-8z" />
      <path d="m28.5 24.1h7.5v-.7h-7.7z" />
      <path d="m29 25.5h7v-.7h-7.3z" />
      <path d="m29.9 26.9h6.1v-.6h-6.6z" />
      <path d="m30.9 28.3h5.1v-.7h-5.7z" />
      <path d="m48.7 27.5.1-.1v-.1l.1-.1.1-.1h.1l.1-.1h.1.1l.1.1h.1l.1.1v.1l.1.1v.1h-.1v-.1l-.1-.1-.1-.1h-.1v-.1h-.1-.1l-.1.1h-.1l-.1.1-.1.1v.1z" />
      <path d="m49.1 27.5v-.2h.2l.1.1h.1v.1h-.1v-.1h-.1-.1v.1z" />
      <path d="m48.7 27.6v-.1h.1v.1z" />
      <path d="m49.1 27.6v-.1h.1v.1h.1l.1-.1h.1v.1z" />
      <path d="m49.7 27.6v-.1h.1v.1z" />
      <path d="m48.7 27.6v.1l.1.1v.1l.1.1h.1l.1.1h.1.1.1.1l.1-.1h.1v-.1l.1-.1v-.1-.1h-.1v.1.1l-.1.1-.1.1h-.1l-.1.1-.1-.1h-.1-.1l-.1-.1-.1-.1v-.1-.1z" />
      <path d="m49.1 27.6v.2h.1v-.2h.1l.1.2h.1l-.2-.2h.1.1z" />
    </g>
    <path
      d="m36.7 19.5h-.1-.1-.1-.1-.1-.1-.1l-.1-.1h-.1-.1-.1l-.2.1h-.1-.1-.1-.1l-.1.1h-.1l-.1.1v.1h-.1v.1.1.1l.1.1v.1l.1.1.2.1h.1l.2.1h.2l.1.1.2.1h.2l.1.1.2.1.1.2.1.1.1.2.1.3v.3.3l-.1.3v.2l-.1.2-.1.2-.1.1-.2.1-.1.2-.2.1h-.2l-.2.1-.1.1h-.2-.2l-.2.1h-.2-.1-.3-.2-.2-.1-.2l-.1-.1h-.2-.1-.1-.1-.1l-.1-.1h-.1-.1-.1l-.1-.1.3-1.1h.1.1.1.1l.1.1h.1.1.1l.2.1h.1.2.1.2.2.1.2l.1-.1h.1l.1-.1v-.1l.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1v-.1h-.1l-.1-.1h-.1-.1l-.1-.1h-.1l-.1-.1h-.1l-.1-.1h-.1l-.1-.1h-.1l-.1-.1-.1-.1-.1-.1-.1-.1v-.1l-.1-.1v-.1l-.1-.1v-.2-.2-.1-.1l.1-.2v-.1-.1-.1l.1-.1v-.1l.1-.1v-.1l.1-.1v-.1h.1l.1-.1v-.1h.1l.1-.1h.1.1l.1-.1h.1.1l.1-.1h.1.1.2l.1-.1h.1.2.1.2.2.2.1.2.1.1l.1.1h.1.1.1.1.1.1z"
      fill="#fff"
    />
    <path
      d="m36.7 19.5h-.1-.1-.1-.1-.1-.1-.1l-.1-.1h-.1-.1-.1l-.2.1h-.1-.1-.1-.1l-.1.1h-.1l-.1.1v.1h-.1v.1.1.1l.1.1v.1l.1.1.2.1h.1l.2.1h.2l.1.1.2.1h.2l.1.1.2.1.1.2.1.1.1.2.1.3v.3.3l-.1.3v.2l-.1.2-.1.2-.1.1-.2.1-.1.2-.2.1h-.2l-.2.1-.1.1h-.2-.2l-.2.1h-.2-.1-.3-.2-.2-.1-.2l-.1-.1h-.2-.1-.1-.1-.1l-.1-.1h-.1-.1-.1l-.1-.1.3-1.1h.1.1.1.1l.1.1h.1.1.1l.2.1h.1.2.1.2.2.1.2l.1-.1h.1l.1-.1v-.1l.1-.1v-.1-.1-.1-.1l-.1-.1-.1-.1h-.1v-.1h-.1l-.1-.1h-.1-.1l-.1-.1h-.1l-.1-.1h-.1l-.1-.1h-.1l-.1-.1h-.1l-.1-.1-.1-.1-.1-.1-.1-.1v-.1l-.1-.1v-.1l-.1-.1v-.2-.2-.1-.1l.1-.2v-.1-.1-.1l.1-.1v-.1l.1-.1v-.1l.1-.1v-.1h.1l.1-.1v-.1h.1l.1-.1h.1.1l.1-.1h.1.1l.1-.1h.1.1.2l.1-.1h.1.2.1.2.2.2.1.2.1.1l.1.1h.1.1.1.1.1.1z"
      fill="none"
      stroke="#fff"
      strokeWidth=".1086"
    />
    <path
      d="m38 17h1.5l-.3 1.3h1l-.2 1.2h-1l-.5 3v.1.1l.1.1.1.1h.1.1.1.1.1.1.1.1.1v-.1l-.1 1v.1h-.1-.1l-.1.1h-.1-.1-.1-.1v.1h-.1-.1-.4-.2l-.2-.1h-.2-.1l-.2-.1h-.1v-.1l-.1-.1h-.1v-.1-.1l-.1-.1v-.1-.2l.1-.1v-.1z"
      fill="#fff"
    />
    <path
      d="m38 17h1.5l-.3 1.3h1l-.2 1.2h-1l-.5 3v.1.1l.1.1.1.1h.1.1.1.1.1.1.1.1.1v-.1l-.1 1v.1h-.1-.1l-.1.1h-.1-.1-.1-.1v.1h-.1-.1-.4-.2l-.2-.1h-.2-.1l-.2-.1h-.1v-.1l-.1-.1h-.1v-.1-.1l-.1-.1v-.1-.2l.1-.1v-.1z"
      fill="none"
      stroke="#fff"
      strokeWidth=".1086"
    />
    <path
      d="m28.8 21.6v.1.1.1.1l.1.1v.1l.1.1v.1h.1l.1.1.1.1.1.1h.1l.1.1h.1.1l.1.1h.2.1.1.2.1.2.1.2l.1-.1h.2.1l.2-.1.1-.1h.2l.1-.1-.2 1.3h-.1l-.1.1h-.1l-.1.1h-.1-.1-.1-.1-.1l-.1.1h-.1-.2-.1-.2-.2-.1-.1-.1l-.2-.1h-.1-.2-.1l-.1-.1h-.2-.1l-.1-.1h-.1l-.2-.1-.1-.1h-.1l-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.2-.1-.1v-.1l-.1-.2v-.1l-.1-.2v-.2-.1-.2-.2-.3-.2-.1-.1-.1l.1-.1v-.1-.2-.1l.1-.1v-.1l.1-.1v-.1l.1-.1v-.2l.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1h.2l.1-.1.2-.1h.2l.1-.1h.2.2l.2-.1h.3.1.1.1l.1.1h.1.1.1l.1.1h.1.1l.1.1h.1l.1.1h.1l.1.1.1.1.1.1.1.1.1.1v.1l.1.1v.1l.1.2v.1.2l.1.1v.2.2.2l-.1.2v.2.3l-.1.3h-3.9l.2-1h2.4v-.2-.1-.1-.2l-.1-.1-.1-.1v-.1l-.1-.1h-.1v-.1h-.1-.1l-.1-.1h-.1-.1-.1-.1l-.1.1h-.1l-.1.1h-.1l-.1.1v.1h-.1l-.1.1v.1l-.1.1v.1.1l-.1.2z"
      fill="#fff"
    />
    <path
      d="m28.8 21.6v.1.1.1.1l.1.1v.1l.1.1v.1h.1l.1.1.1.1.1.1h.1l.1.1h.1.1l.1.1h.2.1.1.2.1.2.1.2l.1-.1h.2.1l.2-.1.1-.1h.2l.1-.1-.2 1.3h-.1l-.1.1h-.1l-.1.1h-.1-.1-.1-.1-.1l-.1.1h-.1-.2-.1-.2-.2-.1-.1-.1l-.2-.1h-.1-.2-.1l-.1-.1h-.2-.1l-.1-.1h-.1l-.2-.1-.1-.1h-.1l-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.1-.2-.1-.1v-.1l-.1-.2v-.1l-.1-.2v-.2-.1-.2-.2-.3-.2-.1-.1-.1l.1-.1v-.1-.2-.1l.1-.1v-.1l.1-.1v-.1l.1-.1v-.2l.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1h.2l.1-.1.2-.1h.2l.1-.1h.2.2l.2-.1h.3.1.1.1l.1.1h.1.1.1l.1.1h.1.1l.1.1h.1l.1.1h.1l.1.1.1.1.1.1.1.1.1.1v.1l.1.1v.1l.1.2v.1.2l.1.1v.2.2.2l-.1.2v.2.3l-.1.3h-3.9l.2-1h2.4v-.2-.1-.1-.2l-.1-.1-.1-.1v-.1l-.1-.1h-.1v-.1h-.1-.1l-.1-.1h-.1-.1-.1-.1l-.1.1h-.1l-.1.1h-.1l-.1.1v.1h-.1l-.1.1v.1l-.1.1v.1.1l-.1.2z"
      fill="none"
      stroke="#fff"
      strokeWidth=".1086"
    />
    <path
      d="m26.8 22.9-.2 1.2h-1.4v-.1-.1-.2h.1v-.1-.1-.1l-.1.1v.1l-.1.1-.2.1h-.1l-.1.1-.2.1h-.1l-.2.1h-.1-.2l-.1.1h-.2-.1-.2l-.1-.1h-.2l-.2-.1h-.1l-.2-.1-.1-.1-.1-.1-.1-.1-.1-.1v-.1l-.1-.1v-.2l-.1-.1v-.1-.1-.1-.2-.2-.2l.1-.2.1-.1.1-.2v-.2l.2-.1.1-.2.1-.1.2-.1.1-.1.2-.1h.2l.1-.1h.2l.2-.1h.1.1.1.1.1.1l.1-.1h.1.1.1.1.1.1.1.1.1.1.1.1v-.1-.1-.1-.1-.1h-.1v-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1-.1-.1-.2-.2-.1-.2-.2l-.1.1h-.2-.2-.1l-.2.1h-.1-.1-.1l-.1.1.3-1.3h.2l.1-.1h.1.1l.2-.1h.1.1.2.1.2.1.2.2.1.2.2.2.1.2l.2.1.1.1h.2l.2.1.1.1.1.2.2.1.1.1.1.2v.2.2l.1.2-.1.2z"
      fill="#fff"
    />
    <path
      d="m26.8 22.9-.2 1.2h-1.4v-.1-.1-.2h.1v-.1-.1-.1l-.1.1v.1l-.1.1-.2.1h-.1l-.1.1-.2.1h-.1l-.2.1h-.1-.2l-.1.1h-.2-.1-.2l-.1-.1h-.2l-.2-.1h-.1l-.2-.1-.1-.1-.1-.1-.1-.1-.1-.1v-.1l-.1-.1v-.2l-.1-.1v-.1-.1-.1-.2-.2-.2l.1-.2.1-.1.1-.2v-.2l.2-.1.1-.2.1-.1.2-.1.1-.1.2-.1h.2l.1-.1h.2l.2-.1h.1.1.1.1.1.1l.1-.1h.1.1.1.1.1.1.1.1.1.1.1.1v-.1-.1-.1-.1-.1h-.1v-.1l-.1-.1-.1-.1h-.1l-.1-.1h-.1-.1-.1-.2-.2-.1-.2-.2l-.1.1h-.2-.2-.1l-.2.1h-.1-.1-.1l-.1.1.3-1.3h.2l.1-.1h.1.1l.2-.1h.1.1.2.1.2.1.2.2.1.2.2.2.1.2l.2.1.1.1h.2l.2.1.1.1.1.2.2.1.1.1.1.2v.2.2l.1.2-.1.2z"
      fill="none"
      stroke="#fff"
      strokeWidth=".1086"
    />
    <path
      d="m25.5 21.5v-.1h-.1-.1-.1-.1-.1-.1-.1-.1-.1v.1h-.1-.1-.1l-.1.1h-.1l-.1.1-.1.1h-.1v.1h-.1v.1l-.1.1v.1.1.1.2.1l.1.1h.1l.1.1h.1.1.1.2.1l.1-.1h.1l.1-.1h.1l.1-.1h.1v-.1h.1v-.1l.1-.1v-.1l.1-.1v-.1-.1-.1-.1-.1z"
      fill="#0084c4"
    />
    <path
      d="m15.5 24.1h-1.5l1.3-7.1h2.5l.2 4.2 1.8-4.2h2.6l-1.2 7.1h-1.5l.9-5.3-2.2 5.3h-1.6v-.2l-.1-.6v-.9l-.1-.9v-1l-.1-.8v-.6-.3h-.1z"
      fill="#fff"
    />
    <path
      d="m15.5 24.1h-1.5l1.3-7.1h2.5l.2 4.2 1.8-4.2h2.6l-1.2 7.1h-1.5l.9-5.3-2.2 5.3h-1.6v-.2l-.1-.6v-.9l-.1-.9v-1l-.1-.8v-.6-.3h-.1z"
      fill="none"
      stroke="#fff"
      strokeWidth=".1086"
    />
    <path
      d="m39.9 24.1h1.5l.5-3.1.1-.1v-.1-.1-.1l.1-.1v-.1l.1-.1.1-.1.1-.1.1-.1h.1l.1-.1.2-.1h.1.2.2.1.1v-.1-.1l.1-.1v-.2-.1l.1-.2v-.1l.1-.1v-.1l.1-.1v-.1l.1-.1v-.1h-.1-.1-.2-.1-.2l-.1.1h-.1l-.1.1h-.1l-.1.1-.1.1h-.1l-.1.1-.1.1v.1l-.1.1-.1.1-.1.1.2-1h-1.5z"
      fill="#fff"
    />
    <path
      d="m39.9 24.1h1.5l.5-3.1.1-.1v-.1-.1-.1l.1-.1v-.1l.1-.1.1-.1.1-.1.1-.1h.1l.1-.1.2-.1h.1.2.2.1.1v-.1-.1l.1-.1v-.2-.1l.1-.2v-.1l.1-.1v-.1l.1-.1v-.1l.1-.1v-.1h-.1-.1-.2-.1-.2l-.1.1h-.1l-.1.1h-.1l-.1.1-.1.1h-.1l-.1.1-.1.1v.1l-.1.1-.1.1-.1.1.2-1h-1.5z"
      fill="none"
      stroke="#fff"
      strokeWidth=".1086"
    />
    <path
      d="m46.5 18.3h.1.2.2.1l.2.1h.1.2l.1.1h.1l.1.1.2.1h.1l.1.1.1.1.1.1.1.1.1.1.1.1v.1l.1.1.1.1v.1l.1.1v.2l.1.1v.2.1l.1.1v.2.1.2.1.2l-.1.2v.2.2l-.1.1v.2l-.1.1v.2l-.1.1v.2l-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1h-.1l-.2.1h-.1l-.1.1h-.2l-.1.1h-.2-.1-.1l-.2.1h-.1-.2-.2-.1l-.1-.1h-.2-.1-.2l-.1-.1h-.1l-.2-.1h-.1l-.1-.1h-.1l-.1-.1-.2-.1-.1-.1-.1-.1v-.1l-.1-.1-.1-.1-.1-.1-.1-.1v-.1l-.1-.1v-.2l-.1-.1v-.2l-.1-.1v-.2-.1-.2-.2-.1-.2-.2-.2l.1-.1v-.2l.1-.1v-.2l.1-.1v-.2l.1-.1.1-.1.1-.1v-.1l.1-.2.1-.1.1-.1h.1l.1-.1.1-.1.2-.1.1-.1h.1l.1-.1h.2l.1-.1h.1l.2-.1h.1.2.1.2z"
      fill="#fff"
    />
    <path
      d="m46.5 18.3h.1.2.2.1l.2.1h.1.2l.1.1h.1l.1.1.2.1h.1l.1.1.1.1.1.1.1.1.1.1.1.1v.1l.1.1.1.1v.1l.1.1v.2l.1.1v.2.1l.1.1v.2.1.2.1.2l-.1.2v.2.2l-.1.1v.2l-.1.1v.2l-.1.1v.2l-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1-.1.1h-.1l-.2.1h-.1l-.1.1h-.2l-.1.1h-.2-.1-.1l-.2.1h-.1-.2-.2-.1l-.1-.1h-.2-.1-.2l-.1-.1h-.1l-.2-.1h-.1l-.1-.1h-.1l-.1-.1-.2-.1-.1-.1-.1-.1v-.1l-.1-.1-.1-.1-.1-.1-.1-.1v-.1l-.1-.1v-.2l-.1-.1v-.2l-.1-.1v-.2-.1-.2-.2-.1-.2-.2-.2l.1-.1v-.2l.1-.1v-.2l.1-.1v-.2l.1-.1.1-.1.1-.1v-.1l.1-.2.1-.1.1-.1h.1l.1-.1.1-.1.2-.1.1-.1h.1l.1-.1h.2l.1-.1h.1l.2-.1h.1.2.1.2z"
      fill="none"
      stroke="#fff"
      strokeWidth=".1086"
    />
    <path d="m48.7 23.8h.4-.1v.4h-.1v-.4z" fill="#fff" />
    <path
      d="m48.7 23.8h.4-.1v.4h-.1v-.4z"
      fill="none"
      stroke="#fff"
      strokeWidth=".1086"
    />
    <path
      d="m49.2 23.8h.1l.2.3.1-.3h.2v.4h-.1v-.3l-.2.3-.2-.3v.3h-.1z"
      fill="#fff"
    />
    <path
      d="m49.2 23.8h.1l.2.3.1-.3h.2v.4h-.1v-.3l-.2.3-.2-.3v.3h-.1z"
      fill="none"
      stroke="#fff"
      strokeWidth=".1086"
    />
    <path
      d="m45.3 21v.2l-.1.2v.2l.1.2v.1.1l.1.2v.1l.1.1.1.1.1.1h.1.1.1l.1.1.2-.1h.1.1.1l.1-.1.1-.1h.1l.1-.1.1-.1v-.1l.1-.2.1-.1v-.2l.1-.2v-.2l.1-.2v-.1-.2-.1-.2-.1l-.1-.1v-.1l-.1-.1v-.1l-.1-.1-.1-.1-.1-.1h-.1-.1l-.1-.1h-.1l-.1.1h-.2-.1l-.1.1-.2.1h-.1l-.1.1v.1l-.1.1-.1.2v.1l-.1.1v.1l-.1.2v.1z"
      fill="#ec1c2e"
    />
  </svg>
));

CCMaestroICon.displayName = 'CCMaestroICon';
