import {
  useQuery,
  type UseQueryResult,
  type DefinedUseQueryResult,
} from '@tanstack/react-query';

import type { GetProductsQuerySelectorResult } from '../types';
import { getProductsQuerySelector } from '../utils';

import { getProducts } from './queries';
import { billingQueryKeys } from './query-keys';

import type { UseQueryBareOptions } from '@/shared/utils/query-client.utils';

type QueryFnType = typeof getProducts;
type QueryKey = typeof billingQueryKeys['products'];
type QueryFnData = Awaited<ReturnType<QueryFnType>>;
type QueryData = GetProductsQuerySelectorResult;
type QueryOptions = UseQueryBareOptions<
  QueryFnData,
  unknown,
  QueryData,
  QueryKey
>;

type DefinedQueryOptions = Omit<QueryOptions, 'initialData'> & {
  initialData: QueryFnData | (() => QueryFnData);
};

type UndefinedQueryOptions = Omit<QueryOptions, 'initialData'> & {
  initialData?: () => undefined;
};

export const getProductsQuery = (options?: QueryOptions) => ({
  staleTime: Infinity,
  cacheTime: Infinity,
  select: getProductsQuerySelector,
  ...options,
  queryKey: billingQueryKeys.products,
  queryFn: getProducts,
});

export function useGetProducts(
  options: DefinedQueryOptions,
): DefinedUseQueryResult<QueryData>;

export function useGetProducts(
  options?: UndefinedQueryOptions,
): UseQueryResult<QueryData>;

export function useGetProducts(options?: QueryOptions) {
  return useQuery(getProductsQuery(options));
}
