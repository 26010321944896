import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const SelectorIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    viewBox="0 0 24 24"
    stroke="currentColor"
    strokeWidth={2}
    fill="none"
    ref={ref}
    {...props}
  >
    <path
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M8 9l4-4 4 4m0 6l-4 4-4-4"
    />
  </svg>
));

SelectorIcon.displayName = 'SelectorIcon';
