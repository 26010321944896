import { type CrmEvent } from '../events';

import { useUsercom } from '@/shared/hooks';
import { useGTM } from '@/shared/providers/gtm.provider';

const crmEventToGtmEvent = (event: CrmEvent) => {
  const { name, ...payload } = event;
  return {
    event: name,
    payload,
  };
};

export const useCRM = () => {
  /* TODO: use gtm should eventually be part of this */
  const gtm = useGTM();
  const usercom = useUsercom();
  const sendEvent = (event: CrmEvent) => {
    const { name, payload } = event;

    gtm.dataLayer.push(crmEventToGtmEvent(event));
    usercom.sendEvent(name, payload);
  };

  return { sendEvent };
};
