import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const CheckIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    viewBox="0 0 36 36"
    className="h-6 w-6"
    ref={ref}
    {...props}
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        fill="#3BD771"
        d="M36,18c0.1,9.9-8.2,18.2-18.4,18C7.9,35.8-0.2,27.7,0,17.5C0.2,7.8,8.3-0.3,18.5,0   C28.2,0.3,36,8.2,36,18z"
      />
      <path
        fillRule="evenodd"
        fill="#FDFEFD"
        d="M15.4,25.7c-0.7,0-1.3-0.3-1.8-0.7c-1.7-1.7-3.4-3.4-5.1-5.1c-1-1-1-2.6,0-3.6   c1-1,2.6-1,3.7,0c1,1,2,2,3,3c0.2,0.2,0.4,0.3,0.6,0c2.7-2.7,5.4-5.4,8.1-8.1c1.4-1.4,3.7-0.9,4.3,0.9c0.3,1,0.1,1.9-0.6,2.7   c-3.4,3.4-6.9,6.9-10.3,10.3C16.7,25.5,16.1,25.7,15.4,25.7z"
      />
    </g>
  </svg>
));

CheckIcon.displayName = 'CheckIcon';
