import {
  type DefinedUseQueryResult,
  useQuery,
  type UseQueryResult,
} from '@tanstack/react-query';

import { getCountries } from './queries';
import { billingQueryKeys } from './query-keys';

import type { UseQueryBareOptions } from '@/shared/utils/query-client.utils';

type QueryFnType = typeof getCountries;
type QueryKey = typeof billingQueryKeys['countries'];
type QueryFnData = Awaited<ReturnType<QueryFnType>>;

type QueryOptions = UseQueryBareOptions<
  QueryFnData,
  unknown,
  QueryFnData,
  QueryKey
>;

type DefinedQueryOptions = Omit<QueryOptions, 'initialData'> & {
  initialData: QueryFnData | (() => QueryFnData);
};

type UndefinedQueryOptions = Omit<QueryOptions, 'initialData'> & {
  initialData?: () => undefined;
};

export function countriesQuery(options?: QueryOptions) {
  return {
    staleTime: Infinity,
    cacheTime: Infinity,
    ...options,
    queryKey: billingQueryKeys.countries,
    queryFn: getCountries,
  };
}

export function useGetCountries(
  options?: UndefinedQueryOptions,
): UseQueryResult<QueryFnData>;

export function useGetCountries(
  options: DefinedQueryOptions,
): DefinedUseQueryResult<QueryFnData>;

export function useGetCountries(options?: QueryOptions) {
  return useQuery(countriesQuery(options));
}
