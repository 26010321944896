import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { isNil } from 'remeda';

import { Button, Modal, type ModalProps } from '@/ui';

type SuccessfulPurchaseModalProps = {
  title?: string;
  description?: string;
  link: string;
};

type Props = ModalProps<'div'> & SuccessfulPurchaseModalProps;

export const SuccessfulPurchaseModal = ({
  link,
  title = 'Thank you!',
  description = 'Your payment was processed successfully and your subscription will be upgraded any second.',
  ...props
}: Props) => {
  const queryClient = useQueryClient();

  const [isDisabled, setIsDisabled] = useState(true);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    // wait for 3s until we redirect to the web app due to webhook processing
    timerRef.current = setTimeout(() => setIsDisabled(false), 3000);

    return () => {
      if (isNil(timerRef.current)) {
        clearTimeout(timerRef.current);
      }
    };
  });

  const handleOnClick = () => {
    queryClient.invalidateQueries(['auth-user']);
    window.location.replace(link);
  };

  return (
    <Modal size="lg" {...props} onClose={() => false}>
      <div className="flex flex-col items-center justify-center text-lg">
        <img
          alt="celebrate"
          src="/assets/images/celebrate-icon.png"
          className="h-40 w-40"
        />
        <p className="mt-8 text-4xl font-bold">{title}</p>
        {description && <p className="mt-6">{description}</p>}
        <p>Thank you for choosing UptimeRobot.</p>
        <Button
          type="button"
          color="primary"
          className="mt-12 w-[222px]"
          onClick={handleOnClick}
          disabled={isDisabled}
        >
          Go to app
        </Button>
      </div>
    </Modal>
  );
};
