import { MAIN_APP_DASHBOARD_URL } from '../constants';

import { type User } from '@/features/auth/types';

export const redirectCancellingCustomers = (user?: User) => {
  if (user?.activeSubscription?.status === 'canceled') {
    window.location.href = MAIN_APP_DASHBOARD_URL;

    return true;
  }

  return false;
};
