interface StoreWithActions {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  actions: Record<string, (...args: any[]) => void>;
}

export function getStoreActions<T extends StoreWithActions>(
  state: T,
): T['actions'] {
  return state.actions;
}
