import { isNil } from 'remeda';
import {
  type DefinedUseQueryResult,
  useQuery,
  type UseQueryResult,
  type QueryFunctionContext,
  type UseQueryOptions,
} from '@tanstack/react-query';
import type { AxiosResponse } from 'axios';

import type { GetPaymentCoupon } from '../types/coupon.types';

import { validateCoupon } from './queries';
import { billingQueryKeys } from './query-keys';

import type { UseQueryBareOptions } from '@/shared/utils/query-client.utils';

type ErrorData = string;
type QueryFnType = typeof validateCoupon<ErrorData>;
type QueryKey = ReturnType<typeof billingQueryKeys['getCoupon']>;
type QueryParams = Parameters<typeof billingQueryKeys['getCoupon']>;
type QueryFnData = Awaited<ReturnType<QueryFnType>>;
type QueryData = ReturnType<typeof querySelector>;
type QueryOptions = UseQueryBareOptions<
  QueryFnData,
  unknown,
  QueryData,
  QueryKey
>;

type ValidCouponResponse = AxiosResponse<GetPaymentCoupon> & {
  isCouponValid: true;
};
type InvalidCouponResponse = AxiosResponse<undefined> & {
  isCouponValid: false;
};
type ValidatedCouponResponse = ValidCouponResponse | InvalidCouponResponse;

type DefinedQueryOptions = Omit<QueryOptions, 'initialData'> & {
  initialData: QueryFnData | (() => QueryFnData);
};

const EXPECTED_ERROR_CODES = [400, 404];
const CACHE_TIME_MS = Infinity;

export const resolveOk =
  (okStatusCodes: number[], is2xxOk = true) =>
  (status: number) => {
    if (is2xxOk && status >= 200 && status < 300) {
      return true;
    }

    return okStatusCodes.includes(status);
  };

const querySelector = (
  response: Awaited<ReturnType<QueryFnType>>,
): ValidatedCouponResponse => {
  const data = response.data;

  const enhancedData =
    typeof data === 'string'
      ? ({ data: undefined, isCouponValid: false } as const)
      : ({ data, isCouponValid: true } as const);

  return { ...response, ...enhancedData };
};

const queryFn = async (ctx: QueryFunctionContext<QueryKey>) => {
  const [_coupon, _getCoupon, couponCode] = ctx.queryKey;

  const response = await validateCoupon<ErrorData>(
    { couponCode },
    { validateStatus: resolveOk(EXPECTED_ERROR_CODES) },
  );

  return response;
};

export const couponQuery = (params: QueryParams, options?: QueryOptions) => {
  const [{ couponCode }] = params;

  return {
    retry: 1,
    staleTime: CACHE_TIME_MS,
    cacheTime: CACHE_TIME_MS,
    enabled: !isNil(couponCode),
    select: querySelector,
    ...options,
    queryKey: billingQueryKeys.getCoupon(...params),
    queryFn,
  };
};

export function useCouponQuery(
  params: QueryParams,
  options: DefinedQueryOptions,
): DefinedUseQueryResult<QueryData>;

export function useCouponQuery(
  params: QueryParams,
  options?: QueryOptions,
): UseQueryResult<QueryData>;

export function useCouponQuery(params: QueryParams, options?: QueryOptions) {
  return useQuery(couponQuery(params, options));
}
