import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const CCVisaIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 40.667"
    ref={ref}
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="#F9F9F9"
      d="M2.134 39.833A1.635 1.635 0 0 1 .5 38.2V2.467c0-.901.733-1.634 1.634-1.634h59.733c.9 0 1.633.733 1.633 1.634v35.734c0 .9-.732 1.633-1.633 1.633H2.134z"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="#C6C6C5"
      d="M61.867 1.333c.625 0 1.133.509 1.133 1.134v35.734c0 .625-.508 1.133-1.133 1.133H2.134A1.135 1.135 0 0 1 1 38.201V2.467c0-.625.509-1.134 1.134-1.134h59.733m0-1H2.134A2.133 2.133 0 0 0 0 2.467v35.734c0 1.178.955 2.133 2.134 2.133h59.733A2.132 2.132 0 0 0 64 38.201V2.467A2.133 2.133 0 0 0 61.867.333z"
    />
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        fill="#0066B2"
        d="M29.452 26.341L26.326 26.341 28.28 14.333 31.407 14.333z"
      />
      <path
        fill="#0066B2"
        d="M23.695 14.333l-2.98 8.26-.353-1.778-1.052-5.399s-.126-1.082-1.483-1.082H12.9l-.058.204s1.507.313 3.271 1.372l2.716 10.433h3.257l4.975-12.009-3.366-.001zm24.592 12.008h2.87l-2.503-12.009H46.14c-1.16 0-1.443.896-1.443.896l-4.662 11.113h3.259l.651-1.783h3.976l.366 1.783zm-3.439-4.248l1.644-4.494.925 4.494h-2.569zm-4.569-4.873l.445-2.579s-1.377-.522-2.813-.522c-1.553 0-5.234.678-5.234 3.976 0 3.102 4.323 3.141 4.323 4.77s-3.878 1.338-5.16.311l-.464 2.695s1.396.68 3.529.68c2.132 0 5.353-1.104 5.353-4.109 0-3.123-4.362-3.414-4.362-4.771s3.045-1.188 4.383-.451z"
      />
      <path
        fill="#F9A533"
        d="M20.362 20.814l-1.052-5.399s-.126-1.082-1.483-1.082H12.9l-.058.204s2.369.491 4.641 2.329c2.172 1.758 2.879 3.948 2.879 3.948z"
      />
    </g>
  </svg>
));

CCVisaIcon.displayName = 'CCVisaIcon';
