import type {
  DefinedUseQueryResult,
  FetchQueryOptions,
  QueryClient,
  QueryKey,
  UseQueryOptions,
  UseQueryResult,
} from '@tanstack/react-query';
import type { AxiosError, AxiosResponse } from 'axios';
import { useEffect, useRef } from 'react';
import { isNil } from 'remeda';

import type { RequiredProperties } from './types.type';

export type UseQueryBareOptions<
  TQueryFnData,
  TError = unknown,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
> = Omit<
  UseQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
  'queryKey' | 'queryFn'
>;

export async function routerQueryLoaderFn<
  TQueryFnData = unknown,
  TError = AxiosError,
  TData = TQueryFnData,
  TQueryKey extends QueryKey = QueryKey,
>(
  queryClient: QueryClient,
  options: RequiredProperties<
    FetchQueryOptions<TQueryFnData, TError, TData, TQueryKey>,
    'queryFn' | 'queryKey'
  >,
): Promise<TData | TQueryFnData> {
  const data = queryClient.getQueryData<TQueryFnData>(options.queryKey);

  if (!isNil(data)) {
    return data;
  }

  const responseData = await queryClient.fetchQuery({ ...options });

  return responseData;
}

/**
 * Hook to substitute `onSuccess()` callback for Query with `initialData`
 * Doesn't work well with query selector that returns new data
 *
 * @param useQueryResult
 * @param callback to be executed
 */
export function useOnQueryData<TQueryData>(
  useQueryResult: UseQueryResult<TQueryData | undefined>,
  callback: (data: TQueryData) => void,
) {
  const dataRef = useRef<TQueryData>();
  const { data, isFetched } = useQueryResult;

  useEffect(() => {
    if (isFetched && dataRef.current !== data && data !== undefined) {
      dataRef.current = data;

      callback(data);
    }
  }, [data, callback, isFetched]);
}
