import type { PropsWithChildren } from 'react';
import { ErrorBoundary, Provider } from '@rollbar/react';
import type { Configuration } from 'rollbar';

import { ErrorModal } from '@/ui';

const rollbarConfig: Configuration = {
  accessToken: '4673487e228647389a226a071c128198',
  environment: import.meta.env.VITE_ENV ?? undefined,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    client: {
      javascript: {
        /* eslint-disable-next-line */
        code_version: '1.0.0',
        /* eslint-disable-next-line */
        source_map_enabled: true,
      },
    },
  },
};

export const RollbarProvider = ({ children }: PropsWithChildren) => {
  const FallbackUI = () => (
    <ErrorModal
      onClose={() => null}
      onAction={() => window.location.replace('/')}
    />
  );

  return (
    <Provider config={rollbarConfig}>
      {/* @ts-ignore */}
      <ErrorBoundary fallbackUI={FallbackUI}>{children}</ErrorBoundary>
    </Provider>
  );
};
