/* eslint-disable camelcase */
export const useUsercom = () => {
  const usercomFuncsReady = typeof window.userengage === 'function';

  const openChat = () => {
    if (usercomFuncsReady) {
      window.userengage('widget.open');
    }
  };

  const sendEvent = (eventName: string, payload?: Record<string, unknown>) => {
    if (usercomFuncsReady) {
      window.userengage(eventName, payload);
    }
  };

  const pageHit = (userBase: {
    isPro: 0 | 1;
    planName?: string;
    monitorCount: number;
  }) => {
    if (typeof window !== 'undefined' && window.UE && window.UE.isReady) {
      window.UE.pageHit({
        is_pro: userBase.isPro,
        plan_name: userBase.planName ?? 'Free',
        monitorCount: userBase.monitorCount,
      });
    }
  };

  return { openChat, sendEvent, pageHit };
};
