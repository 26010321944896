import type { PlanName } from '@/features/billing/types/billing.types';
import type {
  NonNullableField,
  RequiredProperties,
} from '@/shared/utils/types.type';

export enum SubscriptionPaymentPeriod {
  Monthly = 1,
  Yearly = 12,
}

export type SubscriptionFeature =
  | 'ssl-monitoring'
  | 'domain-expiration'
  | 'monitor-advanced-settings'
  | 'maintenance-window'
  | 'heartbeat'
  | 'advanced-notifications'
  | 'psp-customization'
  | 'psp-custom-domain'
  | 'psp-analytics'
  | 'bulk-import'
  | 'bulk-export'
  | 'api'
  | 'team-integrations'
  | 'incident-comments'
  | 'psp-remove-ur-logo'
  | 'psp-password'
  | 'psp-subscribers'
  | 'subusers'
  | 'subusers-notif-only';

type Limits = 'psp' | 'subusers' | 'subusers-notif-only';

type SubscriptionSchedule = {
  currentSubscription: { productId: number; quantity: number }[];
  upcomingSubscription: { productId: number; quantity: number }[];
  createdAt: string;
};

export type Plan = {
  id: number;
  name: PlanName;
  featureDefinition: {
    limits: {
      psp: number;
      subusers: number;
      'subusers-notif-only': number;
    };
    features: string[];
  };
};

type ActiveSubscription = {
  id: number;
  paymentPeriod: SubscriptionPaymentPeriod;
  status: 'active' | 'expired' | 'canceled' | 'terminated';
  monitorLimit: number;
  expirationDate: string;
  extraSeatCount: number;
  extraNotifySeatCount: number;
  created: string;
  provider: 'stripe' | 'paypal' | 'IAP';
  subscriptionSchedule: SubscriptionSchedule | null;
  featureDefinition: {
    limits: {
      psp: number;
      subusers: number;
      'subusers-notif-only': number;
    };
    features: string[];
  };
  plan: Plan;
};

type Organization = {
  id: number;
  name: string;
  memberCount: number;
  pendingInvitationCount: number;
  alertContactCount: number;
};

export type User = {
  id: number;
  email: string;
  registeredAt: string;
  userIsProPlan: 0 | 1;
  subscriptionStatus: 0 | 1;
  currency: string;
  smsRefillEnabled: 0 | 1;
  fullName: string | null;
  monitorsCount: number;
  monitorLimit: number;
  billing: {
    name: string | null;
    address: string | null;
    city: string | null;
    state: string | null;
    zip: string | null;
    country: string | null;
    vatId: string | null;
  };
  activeSubscription: ActiveSubscription | null;
  creditCard?: {
    brand: string;
    lastFourDigits: string;
    expMonth: number;
    expYear: number;
  };
  organization: Organization | null;
  hasBoughtCredits?: boolean;
  featureDefinition: {
    limits: Record<Limits, number>;
    features: SubscriptionFeature[];
  } | null;
  stripeMigrated: boolean;
};

export type SubscribedUser = NonNullableField<User, 'activeSubscription'>;

export type CreditCardUser = RequiredProperties<User, 'creditCard'>;
