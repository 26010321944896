import {
  createContext,
  useEffect,
  useRef,
  useState,
  type PropsWithChildren,
} from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { isNil } from 'remeda';

import { type ModalProps, Modal as BaseModal, Button } from '@/ui';
import { MAIN_APP_DASHBOARD_URL } from '@/shared/constants';

type Props = ModalProps<'div'> & {
  children: React.ReactNode;
};

export const SuccessBillingActionModalContext = createContext<{
  isDisabled: boolean;
}>({ isDisabled: true });

export const Modal = ({ onClose = () => false, children, ...props }: Props) => {
  const [isDisabled, setIsDisabled] = useState(true);
  const timerRef = useRef<NodeJS.Timeout>();

  useEffect(() => {
    // wait for 3s until we redirect to the web app due to webhook processing
    timerRef.current = setTimeout(() => setIsDisabled(false), 3000);

    return () => {
      if (isNil(timerRef.current)) {
        clearTimeout(timerRef.current);
      }
    };
  });

  return (
    <BaseModal size="lg" {...props} onClose={onClose}>
      <SuccessBillingActionModalContext.Provider value={{ isDisabled }}>
        {children}
      </SuccessBillingActionModalContext.Provider>
    </BaseModal>
  );
};

export const Content = (props: PropsWithChildren) => {
  return (
    <div className="flex flex-col items-center justify-center text-lg">
      {props.children}
    </div>
  );
};
export const Header = ({ title = 'Thank you!' }: { title?: string }) => (
  <>
    <img
      alt="celebrate"
      src="/assets/images/celebrate-icon.png"
      className="h-40 w-40"
    />
    <p className="mt-8 mb-6 text-4xl font-bold">{title}</p>
  </>
);

export const Footer = ({
  onClick,
  isDisabled,
  text = 'Go to App',
  link = MAIN_APP_DASHBOARD_URL,
}: {
  onClick?: () => void;
  isDisabled: boolean;
  text?: string;
  link?: string;
}) => {
  const queryClient = useQueryClient();

  const handleClick = () => {
    if (!isNil(onClick)) {
      onClick();
    }

    queryClient.invalidateQueries(['auth-user']);
    window.location.replace(link);
  };

  return (
    <>
      <p>Thank you for choosing UptimeRobot.</p>
      <Button
        type="button"
        color="primary"
        className="mt-12 w-[222px]"
        onClick={handleClick}
        disabled={isDisabled}
      >
        {text}
      </Button>
    </>
  );
};

export const SuccessfulBillingActionModal = {
  Modal,
  Content,
  Header,
  Footer,
};
