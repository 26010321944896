import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const PlusIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    ref={ref}
    fill="none"
    stroke="currentColor"
    strokeWidth={2}
    className="h-6 w-6"
    {...props}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M12 4v16m8-8H4" />
  </svg>
));

PlusIcon.displayName = 'PlusIcon';
