export const FREE_PLAN_ID = 0;
export const LEGACY_PRO_PLAN_ID = 4;
export const NEW_PRICING_RELEASE_TIMESTAMP = 1670835600;
export const EXTRA_CREDITS_REFILL_THRESHOLD = 25;
export const LOGIN_SEAT_ADDON_ID = 1;
export const NOTIFY_SEAT_ADDON_ID = 2;

export const VAT_APPLICABLE_COUNTRIES = [
  'AUT',
  'BEL',
  'BGR',
  'HRV',
  'CYP',
  'CZE',
  'DNK',
  'EST',
  'FIN',
  'FRA',
  'DEU',
  'GRC',
  'HUN',
  'IRL',
  'ITA',
  'LVA',
  'LTU',
  'LUX',
  'MLT',
  'NLD',
  'POL',
  'PRT',
  'ROU',
  'SVK',
  'SVN',
  'ESP',
  'SWE',
  'GBR',
];
