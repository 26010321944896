import { create } from 'zustand';

import { getStoreActions } from './utils';

export interface LoaderStore {
  active: boolean;
  actions: { show: () => void; hide: () => void };
}

export const useLoaderStore = create<LoaderStore>(set => ({
  active: false,
  actions: {
    show: () => set(() => ({ active: true })),
    hide: () => set(() => ({ active: false })),
  },
}));

export const useLoaderActions = () => useLoaderStore(getStoreActions);
