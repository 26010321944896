import type { LoaderFunctionArgs } from 'react-router-dom';
import { isNil } from 'remeda';
import type { QueryClient } from '@tanstack/react-query';

import { routerQueryLoaderFn } from '@/shared/utils/query-client.utils';
import type { LoaderStore } from '@/stores';
import { getProductsQuery } from '@/features/billing/api/get-products.query';
import { couponQuery } from '@/features/billing/api/get-coupon.query';
import { type SubscribedUser } from '@/features/auth/types';
import { redirectCancellingCustomers } from '@/shared/utils/redirect-cancelling-customers.util';

export const updateAddonsRouteLoader =
  (
    queryClient: QueryClient,
    globalLoaderActions: LoaderStore['actions'],
    user: SubscribedUser,
  ) =>
  async ({ request }: LoaderFunctionArgs) => {
    globalLoaderActions.show();

    const isRedirecting = redirectCancellingCustomers(user);

    const url = new URL(request.url);
    const couponCodeUrlParam = url.searchParams.get('coupon');

    const data = await Promise.all([
      routerQueryLoaderFn(queryClient, getProductsQuery()),

      isNil(couponCodeUrlParam)
        ? undefined
        : routerQueryLoaderFn(
            queryClient,
            couponQuery([{ couponCode: couponCodeUrlParam }]),
          ),
    ]);

    if (!isRedirecting) {
      globalLoaderActions.hide();
    }

    return data;
  };
