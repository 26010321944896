import { formatISODate, getIsWithinInstantUpdateThreshold } from '../../utils';

import {
  SuccessfulBillingActionModal,
  SuccessBillingActionModalContext,
} from '@/app/shared/components/success-modal';
import { useSubscribedUser } from '@/features/auth/hooks';
import { useCRM } from '@/features/crm/hooks/use-crm.hook';
import type { ModalProps } from '@/ui';

type BillingPeriodChangeSuccessModalProps = {
  newBillingPeriod: 'monthly' | 'annually';
};

type Props = ModalProps<'div'> & BillingPeriodChangeSuccessModalProps;

export const BillingPeriodChangeSuccessModal = ({
  newBillingPeriod,
  ...props
}: Props) => {
  const crm = useCRM();
  const user = useSubscribedUser();

  const isWithinInstantUpdateThreshold = getIsWithinInstantUpdateThreshold(
    user.activeSubscription.expirationDate,
  );

  const isScheduledDowngrade =
    newBillingPeriod === 'monthly' && !isWithinInstantUpdateThreshold;

  const handleClick = () => {
    if (isScheduledDowngrade) {
      crm.sendEvent({ name: 'subscription_period_change_scheduled' });
    } else {
      crm.sendEvent({ name: 'subscription_period_change_immediate' });
    }
  };

  return (
    <SuccessfulBillingActionModal.Modal {...props} onClose={() => false}>
      <SuccessfulBillingActionModal.Content>
        <SuccessfulBillingActionModal.Header
          title={
            isScheduledDowngrade ? 'Subscription change scheduled' : undefined
          }
        />
        {isScheduledDowngrade ? (
          <p>
            The change in your subscription has been scheduled.
            <br />
            The change will take effect on{' '}
            <strong>
              {formatISODate(user.activeSubscription.expirationDate)}
            </strong>{' '}
            (your renewal date). You can cancel the scheduled change anytime.
          </p>
        ) : (
          <p>
            Your payment was processed successfully and your subscription
            billing period has changed.
          </p>
        )}
        <SuccessBillingActionModalContext.Consumer>
          {({ isDisabled }) => (
            <SuccessfulBillingActionModal.Footer
              isDisabled={isDisabled}
              onClick={handleClick}
            />
          )}
        </SuccessBillingActionModalContext.Consumer>
      </SuccessfulBillingActionModal.Content>
    </SuccessfulBillingActionModal.Modal>
  );
};
