import axios, { type AxiosRequestConfig } from 'axios';

// Currently apis are not ready yet then we use mock api, will be replace later
const mockBaseURL = import.meta.env.VITE_API_BASE_URL_MOCK;
const baseURL = import.meta.env.VITE_API_BASE_URL;
const apiAuthToken = import.meta.env.VITE_API_AUTH_TOKEN;

// eslint-disable-next-line camelcase
const LEGACY_getCookieByName = (name: string) => {
  const value = `; ${document.cookie}`;
  const parts = value.split(`; ${name}=`);
  if (parts.length === 2) return parts.pop()!.split(';').shift();
};

// eslint-disable-next-line camelcase
const LEGACY_auth_interceptor = (config: AxiosRequestConfig) => {
  // Token unique to user. Used for user authentication.
  const accessToken = LEGACY_getCookieByName('ur-access-token');
  // Global token for simple authentication.
  const authToken = import.meta.env.VITE_API_AUTH_TOKEN;

  config.headers = config.headers || {};

  if (accessToken) {
    config.headers['Authorization'] = `Bearer ${accessToken}`;
  } else if (authToken) {
    config.headers['Authorization'] = `Bearer ${authToken}`;
  }

  return config;
};

// eslint-disable-next-line camelcase
const LEGACY_headers = {
  Authorization: `Bearer ${apiAuthToken}`,
};

export const mockClient = axios.create({
  baseURL: mockBaseURL,
  timeout: 10_000,
  headers: {},
});

export const client = axios.create({
  baseURL,
  timeout: 10_000,
  withCredentials: true,
  // eslint-disable-next-line camelcase
  ...LEGACY_headers,
});

client.interceptors.request.use(LEGACY_auth_interceptor);
