import { createQueryKeys } from '@lukemorales/query-key-factory';

import type { PaymentMethod } from '../types';
import type { GetCouponPayload } from '../types/coupon.types';

export const billingQueryKeys = createQueryKeys('billing', {
  products: null,
  price: (
    paymentMethod: PaymentMethod,
    billingPeriod: 'monthly' | 'annually',
    data: {
      extraCredits?: string;
      monitors?: string;
      loginSeat?: string;
      notifySeats?: number;
      vatId?: string;
      countryCode: string;
      couponCode?: string;
    },
  ) => [paymentMethod, billingPeriod, data],
  seatPrice: (extraLoginSeatCount: number, couponCode?: string) => [
    extraLoginSeatCount,
    couponCode,
  ],
  getCoupon: ({ couponCode }: GetCouponPayload) => [couponCode],
  vat: (countryCode: string, vatId?: string) => [countryCode, vatId],
  countries: null,
});
