import classNames from 'classnames';
import {
  forwardRef,
  useRef,
  type ChangeEvent,
  type ComponentPropsWithoutRef,
} from 'react';

import { MinusIcon, PlusIcon } from '@/icons';
import { useForkRef } from '@/shared/hooks';

type Props = ComponentPropsWithoutRef<'input'> & {
  containerClassName?: string;
};

export const Counter = forwardRef<HTMLInputElement, Props>(
  ({ onChange, className, containerClassName, ...rest }: Props, ref) => {
    const internalInputRef = useRef<HTMLInputElement | null>(null);
    const inputRef = useForkRef(internalInputRef, ref);

    const handleIncrease = () => {
      const inputValue =
        Number(internalInputRef.current && internalInputRef.current.value) ||
        Number(rest.value) ||
        0;

      if (rest.max !== undefined && inputValue >= Number(rest.max)) {
        return;
      }

      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      onChange?.({
        target: {
          ...internalInputRef.current!,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value: inputValue + (rest.step ?? 1),
        },
      });
    };

    const handleDecrease = () => {
      const inputValue =
        Number(internalInputRef.current!.value) || Number(rest.value) || 0;

      if (rest.min !== undefined && inputValue <= Number(rest.min)) return;

      onChange?.({
        target: {
          ...internalInputRef.current!,
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          value: inputValue - (rest.step ?? 1),
        },
      });
    };

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
      let value = e.target.valueAsNumber;

      if (Number.isNaN(value)) value = Number(rest.min) || 0;
      else if (rest.min !== undefined && value < Number(rest.min))
        value = Number(rest.min);
      else if (rest.max !== undefined && value > Number(rest.max))
        value = Number(rest.max);

      onChange?.({
        ...e,
        target: {
          ...e.target,
          value: value.toString(),
          valueAsNumber: value,
        },
      });
    };

    return (
      <div
        className={classNames(
          'flex flex-grow-0 items-center space-x-2.5',
          containerClassName,
        )}
      >
        <button
          className="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-gray-400 px-1 text-white focus:outline-none"
          onClick={handleDecrease}
          type="button"
        >
          <MinusIcon className="h-3 w-3" />
        </button>
        <input
          className={classNames(
            'number-input-clear m-0 inline-flex appearance-none rounded border-2 border-gray-300 py-2 px-3 text-center focus:outline-none',
            className,
          )}
          type="number"
          ref={inputRef}
          onChange={handleChange}
          {...rest}
        />
        <button
          className="flex h-[18px] w-[18px] items-center justify-center rounded-full bg-primary-main text-white focus:outline-none"
          onClick={handleIncrease}
          type="button"
        >
          <PlusIcon className="h-3 w-3" />
        </button>
      </div>
    );
  },
);

Counter.displayName = 'Counter';
