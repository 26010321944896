import type { IConfig } from '@unleash/proxy-client-react';

const url = import.meta.env.VITE_UNLEASH_URL_PROXY;
const clientKey = import.meta.env.VITE_UNLEASH_CLIENT_KEY;
const refreshInterval = Number(import.meta.env.VITE_UNLEASH_REFRESH_INTERVAL);
const appName = import.meta.env.VITE_UNLEASH_APP_NAME;
const environment = import.meta.env.VITE_UNLEASH_ENVIRONMENT;

if (!url || !clientKey || !refreshInterval || !appName || !environment) {
  throw new Error(
    'Did you forget to set one of the environment variables for Unleash service?',
  );
}

export const unleashConfig: IConfig = {
  url,
  clientKey,
  refreshInterval,
  appName,
  environment,
};
