import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const CCAmericanExpressIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 40.667"
    ref={ref}
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m2.134 39.833c-.901 0-1.634-.732-1.634-1.633v-35.734c0-.9.733-1.633 1.634-1.633h59.733c.9 0 1.633.732 1.633 1.633v35.735c0 .9-.732 1.633-1.633 1.633h-59.733z"
      fill="#f9f9f9"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m61.867 1.333c.625 0 1.133.508 1.133 1.133v35.735c0 .625-.508 1.133-1.133 1.133h-59.733c-.625-.001-1.134-.508-1.134-1.133v-35.735c0-.625.509-1.133 1.134-1.133zm0-1h-59.733c-1.178 0-2.134.955-2.134 2.133v35.735c0 1.178.956 2.133 2.134 2.133h59.733c1.177 0 2.133-.955 2.133-2.133v-35.735c0-1.177-.956-2.133-2.133-2.133z"
      fill="#c6c6c5"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m19.857 19.402v-.64l-.307.64h-2.649l-.332-.687v.687h-4.953l-.586-1.385h-.992l-.619 1.385h-2.57v-4.182l1.884-4.26h3.604l.506 1.207v-1.197h4.409l.969 2.032.948-2.042h13.917s.73-.01 1.209.328l-.002-.328h3.506v.462s.631-.462 1.563-.462h6.187l.545 1.226v-1.226h3.964l.719 1.226v-1.226h3.791v8.449h-3.894l-.915-1.473v1.465h-4.839l-.683-1.509h-.947l-.641 1.509h-2.928s-1.092-.014-1.91-.6v.6h-5.832s0-1.684 0-1.971c0-.286-.22-.267-.22-.267h-.212v2.237h-11.69z"
      fill="#fff"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m16.948 21.232h7.662l1.105 1.251 1.131-1.251h5.89s.555.014 1.08.294v-.294h5.244c.666 0 1.228.333 1.228.333v-.333h6.741v.307s.479-.307 1.125-.307h4.395v.307s.553-.307 1.139-.307 3.447 0 3.447 0v7.961s-.451.514-1.391.514h-4.549v-.252s-.432.252-1.09.252c-.66 0-12.08 0-12.08 0s0-1.854 0-2.058c0-.203-.088-.239-.269-.239h-.166v2.297h-3.773v-2.283s-.42.227-1.119.227-1.357 0-1.357 0v2.059h-4.652l-1.059-1.293-1.131 1.293h-7.549v-8.477h-.002z"
      fill="#fff"
    />
    <g xmlns="http://www.w3.org/2000/svg" fill="#0073a5">
      <path d="m41.096 22.501h4.806v1.291h-3.336v1.124h3.245v1.136h-3.245v1.183h3.336v1.259h-4.806z" />
      <path d="m55.191 24.835c1.809.094 1.959.996 1.959 1.959 0 1.121-.924 1.725-1.885 1.725h-3.09v-1.284h2.406c.408 0 1.016 0 1.016-.575 0-.271-.104-.447-.541-.492-.191-.027-.908-.067-1.016-.069-1.634-.043-2.014-.854-2.014-1.845 0-1.123.789-1.736 1.752-1.736h3.091v1.282h-2.263c-.514.006-1.067-.061-1.067.521 0 .368.272.441.621.476.123-.003.924.04 1.031.038z" />
      <path d="m49.654 24.835c1.809.094 1.959.996 1.959 1.959 0 1.121-.922 1.725-1.885 1.725h-3.088v-1.284h2.406c.408 0 1.018 0 1.018-.575 0-.271-.107-.447-.543-.492-.193-.027-.908-.067-1.017-.069-1.633-.043-2.012-.854-2.012-1.845 0-1.123.788-1.736 1.752-1.736h3.088v1.282h-2.259c-.516.006-1.07-.061-1.07.521 0 .368.273.441.622.476.121-.003.924.04 1.029.038z" />
      <path d="m32.217 22.515h-5.162l-1.685 1.857-1.631-1.871h-5.797v5.99h5.61l1.764-1.979 1.725 1.992h2.822v-2.033h1.979c.762 0 2.153-.025 2.153-2.125.001-1.497-1.056-1.831-1.778-1.831zm-9.434 4.72h-3.39v-1.183h3.243v-1.137h-3.243v-1.125l3.577.008 1.432 1.632zm5.623.695-2.112-2.5 2.112-2.341zm3.316-2.69h-1.832v-1.44h1.837c.615 0 .81.396.81.689 0 .402-.213.751-.815.751z" />
      <path d="m39.32 25.778c.804-.399 1.018-.937 1.018-1.657 0-1.283-1.043-1.604-1.766-1.604h-3.823v5.989h1.47v-2.112h1.928c.506 0 .707.508.721 1.017l.04 1.098h1.432l-.054-1.23c-.002-.967-.268-1.421-.966-1.501zm-1.269-.62h-1.832v-1.355h1.84c.614 0 .809.395.809.688-.002.399-.216.667-.817.667z" />
      <path d="m35.338 12.161h1.471v5.998h-1.471z" />
      <path d="m23.298 12.167h4.806v1.291h-3.334v1.124h3.242v1.135h-3.242v1.184h3.334v1.258h-4.806z" />
      <path d="m33.551 15.43c.803-.4 1.018-.937 1.018-1.659 0-1.283-1.043-1.604-1.766-1.604h-3.824v5.991h1.471v-2.113h1.924c.509 0 .71.508.722 1.016l.041 1.098h1.432l-.055-1.231c0-.964-.268-1.418-.963-1.498zm-1.27-.62h-1.832v-1.357h1.838c.615 0 .809.395.809.688 0 .401-.213.669-.815.669z" />
      <path d="m19.907 12.167-1.805 4.019-1.799-4.019h-2.306v5.77l-2.567-5.77h-1.947l-2.621 5.991h1.552l.574-1.338h2.936l.583 1.338h2.961v-4.447l1.965 4.447h1.338l2.012-4.373v4.373h1.471v-5.991zm-10.364 3.362.877-2.094.914 2.094z" />
      <path d="m51.986 12.165v4.148l-2.48-4.148h-2.173v5.626l-2.507-5.623h-1.946l-2.026 4.641s-.854 0-.94-.021c-.354-.072-.909-.322-.916-1.379v-.435c0-1.404.765-1.507 1.739-1.507h.908v-1.302h-1.92c-.67 0-2.123.511-2.172 2.971-.035 1.659.688 3.026 2.318 3.026h1.939l.573-1.338h2.937l.58 1.338h2.902v-4.388l2.646 4.388h2.006v-5.997zm-9.034 3.367.876-2.094.922 2.094z" />
    </g>
  </svg>
));

CCAmericanExpressIcon.displayName = 'CCAmericanExpressIcon';
