import classNames from 'classnames';
import {
  forwardRef,
  useId,
  type ComponentPropsWithoutRef,
  type ForwardedRef,
} from 'react';
import {
  useFormContext,
  type FieldError,
  type RegisterOptions,
} from 'react-hook-form';

import { useForkRef } from '@/shared/hooks';

export type TextFieldProps = ComponentPropsWithoutRef<'input'> & {
  label?: string;
  registerOptions?: RegisterOptions;
};

export const TextField = forwardRef(
  (
    { className, label, name, ...rest }: TextFieldProps,
    ref: ForwardedRef<HTMLInputElement>,
  ) => {
    if (!name) {
      throw new Error('TextField must have a name');
    }

    const id = useId();
    const errorMessageId = useId();
    const { formState, register } = useFormContext();
    const errorMessage = formState.errors[name] as FieldError | undefined;
    const { ref: registeredRef, ...inputPropsFromContext } = register(
      name,
      rest.registerOptions,
    );

    const inputRef = useForkRef(registeredRef, ref);

    return (
      <div className={classNames('flex w-full flex-col', className)}>
        {label && (
          <label htmlFor={id} className="text-sm font-medium text-gray-500">
            {label}
          </label>
        )}
        <input
          className={classNames(
            'mt-1 w-full rounded border-2 border-gray-300 bg-white py-2 pl-3 pr-8 placeholder-gray-400 focus:outline-none disabled:bg-gray-200 disabled:text-gray-500 disabled:opacity-100',
          )}
          ref={inputRef}
          aria-invalid={!!errorMessage}
          id={id}
          aria-describedby={errorMessage ? errorMessageId : undefined}
          type="text"
          {...inputPropsFromContext}
          {...rest}
        />
        {errorMessage && (
          <div
            id={errorMessageId}
            className="w-full bg-red-50 px-3 py-1 text-sm text-red-500"
          >
            {errorMessage.message}
          </div>
        )}
      </div>
    );
  },
);

TextField.displayName = 'TextField';
