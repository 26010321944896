import { Suspense, type PropsWithChildren } from 'react';
import { QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { FlagProvider } from '@unleash/proxy-client-react';

import { RollbarProvider } from './rollbar.provider';

import { unleashConfig } from '@/configs/unleash.config';
import { AuthProvider } from '@/features/auth/auth';
import { queryClient } from '@/query-client';
import { GoogleTagManagerProvider } from '@/shared/providers/gtm.provider';
import { UserComProvider } from '@/shared/providers/user-com.provider';

export const AppProviders = ({ children }: PropsWithChildren) => (
  <Suspense>
    <RollbarProvider>
      <FlagProvider config={unleashConfig}>
        <QueryClientProvider client={queryClient}>
          <AuthProvider>
            <GoogleTagManagerProvider>
              <UserComProvider>
                {children}
                {import.meta.env.DEV && (
                  <ReactQueryDevtools initialIsOpen={false} />
                )}
              </UserComProvider>
            </GoogleTagManagerProvider>
          </AuthProvider>
        </QueryClientProvider>
      </FlagProvider>
    </RollbarProvider>
  </Suspense>
);
