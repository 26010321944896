import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const ErrorIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => {
  return (
    <svg
      ref={ref}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      version="1.1"
      viewBox="0 0 318 318"
      {...props}
    >
      <g>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M134.8,204.3c1.3,2.8,2.3,4.9,3.3,6.9c2.5,5.1,5,10.2,8.7,14.5   c8.2,9.8,19.5,9.8,27.7-0.1c2.9-3.5,4.9-7.5,6.8-11.6c1.5-3.1,2.9-6.2,4.7-10c1.3,2.7,2.3,4.7,3.3,6.8c2.6,5.4,5,10.9,8.8,15.6   c7.4,9.1,18.2,8.9,25.3-0.5c2.8-3.8,4.7-8,6.7-12.2c2.5-5.4,5.2-10.8,7.7-16.2c1.3-2.8,2.6-2.4,4.4-0.5c1.9,2,1.9,4.5,2.3,6.9   c1.1,6.9,6.7,11.6,13.2,11.1c6.8-0.5,11.7-5.9,11.6-13.1c0-13.3-8.7-25.7-21.3-30.4c-12.9-4.8-21.8-2.1-29.3,9.5   c-3.1,4.8-5.5,9.9-8.5,15.3c-1.5-3-2.8-5.6-4.1-8.3c-2.3-4.6-4.8-9.2-8.4-12.9c-7.4-7.8-17.3-7.6-24.4,0.4c-2.7,3-4.6,6.5-6.3,10.1   c-2.1,4.5-4.2,9-6.5,14.1c-1.1-2.2-1.9-4-2.8-5.7c-3.1-6.4-5.8-13.1-10.6-18.6c-6.9-7.9-16.8-8.1-23.9-0.4c-3.6,3.8-5.8,8.5-8.3,13   c-1.7,3.1-2.7,6.7-5.2,9.7c-3.1-6.4-5.5-12.8-9.4-18.4c-6-8.7-12.5-11.3-22.8-9c-14.3,3.1-24.6,14.8-26.1,29.8   c-0.8,7.9,4,14.2,11.3,15c7,0.8,12.6-4.2,13.6-12.2c0.4-3.1,1.3-5.8,4.7-7.3c1.2,2.4,2.3,4.8,3.5,7.2c3.2,6.6,5.9,13.4,9.5,19.7   c8.2,14.2,21.7,14.4,30.9,0.8C128.6,217.4,131.2,211.1,134.8,204.3z M105.2,103.1c-8.6-0.1-15.6,6.7-15.8,15.3   c-0.2,8.4,6.8,15.7,15.3,15.9c8.7,0.2,16.1-6.9,16.1-15.5C120.7,110.3,113.7,103.2,105.2,103.1z M214.3,103.1   c-8.7,0-15.5,6.8-15.5,15.5c0,8.7,6.7,15.6,15.3,15.7c8.8,0.1,15.9-7,15.8-15.7C229.9,110,222.9,103.1,214.3,103.1z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M134.8,204.3c-3.5,6.8-6.2,13.2-10,18.8   c-9.2,13.7-22.7,13.4-30.9-0.8c-3.7-6.3-6.4-13.1-9.5-19.7c-1.1-2.4-2.3-4.8-3.5-7.2c-3.4,1.6-4.4,4.2-4.7,7.3   c-1,8-6.5,12.9-13.6,12.2c-7.3-0.8-12.1-7.1-11.3-15c1.5-15,11.8-26.7,26.1-29.8c10.3-2.2,16.8,0.3,22.8,9c3.9,5.6,6.3,12,9.4,18.4   c2.6-3,3.5-6.5,5.2-9.7c2.5-4.5,4.7-9.2,8.3-13c7.2-7.7,17-7.6,23.9,0.4c4.8,5.5,7.4,12.2,10.6,18.6c0.9,1.8,1.7,3.5,2.8,5.7   c2.3-5.1,4.4-9.6,6.5-14.1c1.7-3.6,3.6-7.1,6.3-10.1c7.2-8,17.1-8.2,24.4-0.4c3.6,3.8,6,8.3,8.4,12.9c1.3,2.7,2.6,5.3,4.1,8.3   c2.9-5.4,5.4-10.5,8.5-15.3c7.5-11.5,16.4-14.3,29.3-9.5c12.6,4.7,21.3,17.1,21.3,30.4c0,7.2-4.8,12.6-11.6,13.1   c-6.5,0.5-12.1-4.2-13.2-11.1c-0.4-2.5-0.4-5-2.3-6.9c-1.8-1.8-3.1-2.3-4.4,0.5c-2.5,5.4-5.1,10.8-7.7,16.2   c-2,4.2-3.9,8.5-6.7,12.2c-7.1,9.4-17.9,9.7-25.3,0.5c-3.8-4.7-6.2-10.2-8.8-15.6c-1-2-2-4-3.3-6.8c-1.8,3.8-3.3,6.9-4.7,10   c-1.9,4.1-3.9,8.1-6.8,11.6c-8.2,9.9-19.5,9.9-27.7,0.1c-3.7-4.4-6.1-9.5-8.7-14.5C137.1,209.2,136.1,207.1,134.8,204.3z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M105.2,103.1c8.5,0.1,15.6,7.1,15.5,15.6   c0,8.7-7.4,15.7-16.1,15.5c-8.5-0.2-15.5-7.5-15.3-15.9C89.5,109.9,96.6,103.1,105.2,103.1z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M214.3,103.1c8.6,0,15.6,6.8,15.6,15.4c0.1,8.8-7,15.8-15.8,15.7   c-8.6-0.1-15.3-7-15.3-15.7C198.9,109.9,205.7,103.1,214.3,103.1z"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          fill="currentColor"
          d="M318,159C318,71.2,246.8,0,159,0c-2.6,0-5.2,0.1-7.8,0.2   c-0.9,0-1.8,0.1-2.6,0.2c-0.1,0-0.2,0-0.3,0C78.6,5.3,20.4,54.1,3.9,123.8C1.4,135.1,0,146.9,0,159c0,87.8,71.2,159,159,159   S318,246.8,318,159z M24.8,159.5C25,85.2,85.6,24.8,159.9,24.9c74.5,0.1,135.1,60.9,134.9,135.4c-0.2,74.3-60.8,134.7-135.1,134.6   C85.2,294.8,24.6,233.9,24.8,159.5z"
        />
      </g>
    </svg>
  );
});

ErrorIcon.displayName = 'ErrorIcon';
