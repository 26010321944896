export const MAIN_APP_DASHBOARD_URL = `${
  import.meta.env.VITE_DASHBOARD_APP_URL
}/account/billing`;

export const MAIN_APP_MIGRATION_URL = `${
  import.meta.env.VITE_MAIN_APP_URL
}/to-legacy-dashboard?redirectTo=migration&ref=migrationFromCheckout`;

export const STRIPE_MANAGE_SUBSCRIPTION_URL = `${
  import.meta.env.VITE_DASHBOARD_APP_URL
}/external?redirectTo=stripePortal`;
