import * as R from 'remeda';

import { useOptionalUser } from '@/features/auth/hooks/use-optional-user.hook';
import type { SubscribedUser, User } from '@/features/auth/types';

export const useUser = (): User => {
  const user = useOptionalUser();

  if (!user) {
    throw new Error(
      'No user found, but user is required by useUser. If user is optional, try useOptionalUser instead.',
    );
  }

  return user;
};

export const useSubscribedUser = (): SubscribedUser => {
  const user = useUser();

  return user as SubscribedUser;
};

export const isSubscribedUser = (user: User): user is SubscribedUser => {
  return !R.isNil(user.activeSubscription);
};
