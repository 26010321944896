import { Navigate, useLocation } from 'react-router-dom';
import { type ReactNode } from 'react';

import { GlobalLoader } from '@/ui';
import { useAuth } from '@/features/auth/auth';

export const RequireAuth = ({ children }: { children: ReactNode }) => {
  const { user, isLoggingIn, error } = useAuth();
  const location = useLocation();

  if (!isLoggingIn && !user && !error) {
    return null;
  } else if (isLoggingIn) {
    return <GlobalLoader />;
  } else if (error) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return <>{children}</>;
};
