import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';

export const billingFormZodSchemaResolver = zodResolver(
  z.object({
    name: z.string().min(3, 'Name must contain at least 3 characters'),
    address: z.string().min(3, 'Address must contain at least 3 characters.'),
    city: z.string().min(3, 'Address must contain at least 3 characters.'),
    zip: z
      .string()
      .min(4, 'Zip code must contain at least 4 characters.')
      .max(10, 'Zip code must contain at most 10 characters'),
    country: z.string().min(1, 'Country must not be empty'),
    extraCredits: z.preprocess(
      v => (Number.isNaN(Number(v)) ? v : Number(v)),
      z.union([
        z.literal('None'),
        z // eslint-disable-next-line camelcase
          .number({ invalid_type_error: 'Extra credits value is not a number' })
          .positive('Extra credits value must be a positive number'),
      ]),
    ),
    smsRefill: z.boolean(),
  }),
);

export const createSubscriptionBillingFormZodSchemaResolver = zodResolver(
  z.object({
    name: z.string().min(3, 'Name must contain at least 3 characters'),
    address: z.string().min(3, 'Address must contain at least 3 characters.'),
    city: z.string().min(3, 'Address must contain at least 3 characters.'),
    zip: z
      .string()
      .min(4, 'Zip code must contain at least 4 characters.')
      .max(10, 'Zip code must contain at most 10 characters'),
    country: z.string().min(1, 'Country must not be empty'),
    loginSeat: z.preprocess(
      v => (Number.isNaN(Number(v)) ? v : Number(v)),
      z
        // eslint-disable-next-line camelcase
        .number({ invalid_type_error: 'Login seat value is not a number' })
        .min(0, 'Login seat value must be a positive number'),
    ),
    extraCredits: z.preprocess(
      v => (Number.isNaN(Number(v)) ? v : Number(v)),
      z.union([
        z.literal('None'),
        z // eslint-disable-next-line camelcase
          .number({ invalid_type_error: 'Extra credits value is not a number' })
          .positive('Extra credits value must be a positive number'),
      ]),
    ),
  }),
);
