import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const AvatarIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    viewBox="0 0 300 300"
    ref={ref}
    {...props}
  >
    <g>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M15.7,300c-4.5-3.4-5.9-7.9-5.7-13.5c0.8-28.3,7.5-54.6,26.6-76.4   c15.2-17.3,34.5-27.9,56.5-33.4c40.2-10.2,80.6-10.5,120.3,2.1c45.8,14.6,70.6,46.8,75.5,94.6c0.4,4.4,0.4,8.8,0.9,13.1   c0.6,5.6-1.2,10.1-5.7,13.5C194.7,300,105.2,300,15.7,300z M32.5,278.7c78.5,0,156.5,0,234.9,0c-1.3-17.4-5.6-33.3-15.4-47.4   c-13.5-19.3-32.9-29.3-55.1-34.6c-24.1-5.9-48.6-6.2-73.2-3.6c-20.4,2.1-40,7.4-57,19.5C44,228.6,34.7,251.6,32.5,278.7z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        fill="currentColor"
        d="M222.8,71.6c-1,30.1-10.9,52.7-33,68.7   c-27.9,20.2-64.2,16.8-88.7-7.5c-27.5-27.4-32.1-73-9.6-103.4C109.6,5,134.7-3.8,164,1.5c28.9,5.3,47.5,23.2,55.7,51.5   C221.7,60,222.1,67.4,222.8,71.6z M200.7,77.8c0.3-25.7-12.4-45.1-32.5-52.5c-20.6-7.6-43.7-1.8-57.2,14.4   c-17.8,21.4-15.1,57.1,5.7,77.7c18.9,18.7,47.7,18.7,66.6-0.1C195,105.7,200.3,91.3,200.7,77.8z"
      />
    </g>
  </svg>
));

AvatarIcon.displayName = 'AvatarIcon';
