import { ErrorModal, type MinimalModalProps } from '@/ui';

type Props = MinimalModalProps & {
  title?: string;
  description?: string;
};

export const PaymentCouponErrorModal = ({
  title = 'Coupon is invalid',
  description = 'Please enter valid coupon code.',
  onClose,
}: Props) => {
  return (
    <ErrorModal title={title} description={description} onClose={onClose} />
  );
};
