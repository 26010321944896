import { type ReactElement } from 'react';

import { ModalType, useModalActions, useModalStore } from '@/stores';
import {
  LegacyPlanErrorModal,
  PaymentCouponErrorModal,
  PaymentCouponSuccessModal,
  PaymentErrorModal,
  SubscriptionChangeNotPossibleModal,
  SuccessfulPurchaseModal,
  SuccessfulSubscriptionScheduleCancel,
} from '@/features/billing/components';
import { ErrorModal } from '@/ui';
import { BillingPeriodChangeSuccessModal } from '@/features/billing/components/billing-period-change-success-modal';
import { SubscriptionChangeSuccessModal } from '@/features/billing/components/subscription-change-success-modal';

export function ModalManager(): ReactElement | null {
  const activeModal = useModalStore(state => state.active);
  const modalActions = useModalActions();
  const { props } = activeModal;

  const onClose = () =>
    props?.onClose ? props?.onClose(true) : modalActions.close();

  const isOpen = !!activeModal.type;

  const otherProps = {
    onClose,
    open: isOpen,
    unmount: undefined /* type error if unmount !== undefined */,
  };

  switch (activeModal.type) {
    case null:
      return null;

    case ModalType.Error:
      return <ErrorModal {...activeModal.props} {...otherProps} />;

    case ModalType.PaymentSuccess:
      return <SuccessfulPurchaseModal {...activeModal.props} {...otherProps} />;
    case ModalType.PaymentError:
      return <PaymentErrorModal {...activeModal.props} {...otherProps} />;

    case ModalType.LegacyPlanError:
      return <LegacyPlanErrorModal {...activeModal.props} {...otherProps} />;

    case ModalType.BillingPeriodChangeSuccess:
      return (
        <BillingPeriodChangeSuccessModal
          {...activeModal.props}
          {...otherProps}
        />
      );

    case ModalType.SubscriptionChangeSuccess:
      return (
        <SubscriptionChangeSuccessModal
          {...activeModal.props}
          {...otherProps}
        />
      );

    case ModalType.SubscriptionChangeNotPossible:
      return (
        <SubscriptionChangeNotPossibleModal
          {...activeModal.props}
          {...otherProps}
        />
      );

    case ModalType.PaymentCouponSuccess:
      return (
        <PaymentCouponSuccessModal {...activeModal.props} {...otherProps} />
      );

    case ModalType.PaymentCouponError:
      return <PaymentCouponErrorModal {...activeModal.props} {...otherProps} />;

    case ModalType.SuccessfulSubscriptionScheduleCancel:
      return (
        <SuccessfulSubscriptionScheduleCancel
          {...activeModal.props}
          {...otherProps}
        />
      );
    default:
      return null;
  }
}
