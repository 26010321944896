import {
  type QueryFunctionContext,
  useQuery,
  type DefinedUseQueryResult,
  type UseQueryResult,
} from '@tanstack/react-query';

import { getVatInfo } from './queries';
import { billingQueryKeys } from './query-keys';

import { queryClient } from '@/query-client';
import type { UseQueryBareOptions } from '@/shared/utils/query-client.utils';

export type QueryFnType = typeof getVatInfo;
export type QueryKey = ReturnType<typeof billingQueryKeys['vat']>;
export type QueryParams = Parameters<typeof billingQueryKeys['vat']>;
type QueryFnData = Awaited<ReturnType<QueryFnType>>;

export type QueryOptions = UseQueryBareOptions<
  QueryFnData,
  unknown,
  QueryFnData,
  QueryKey
>;

type DefinedQueryOptions = Omit<QueryOptions, 'initialData'> & {
  initialData: QueryFnData | (() => QueryFnData);
};

export const queryFn = (ctx: QueryFunctionContext<QueryKey>) => {
  const [_billing, _vat, countryCode, vatId] = ctx.queryKey;

  return getVatInfo({ countryCode, vatId }, { signal: ctx.signal });
};

export const vatQuery = (params: QueryParams, options?: QueryOptions) => ({
  ...options,
  queryKey: billingQueryKeys.vat(...params),
  queryFn,
});

export function useVatQuery(
  params: QueryParams,
  options: DefinedQueryOptions,
): DefinedUseQueryResult<QueryFnData>;

export function useVatQuery(
  params: QueryParams,
  options?: QueryOptions,
): UseQueryResult<QueryFnData>;

export function useVatQuery(params: QueryParams, options?: QueryOptions) {
  return useQuery(vatQuery(params, options));
}

export const vatQueryFetch = (countryCode: string, vatId: string) =>
  queryClient.fetchQuery<
    Awaited<ReturnType<QueryFnType>>,
    unknown,
    Awaited<ReturnType<QueryFnType>>,
    QueryKey
  >({
    queryFn: queryFn,
    queryKey: billingQueryKeys.vat(countryCode, vatId),
    staleTime: Infinity,
    cacheTime: Infinity,
  });
