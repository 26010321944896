import { useMemo, type MutableRefObject } from 'react';

type CallbackRef<T extends HTMLElement | null = HTMLElement | null> = (
  node: T,
) => any;
type AnyRef<T extends HTMLElement | null = HTMLElement | null> =
  | CallbackRef<T>
  | MutableRefObject<T>;

const setRef = <T extends HTMLElement | null>(
  ref: AnyRef<T> | null,
  value: T,
) => {
  if (typeof ref === 'function') {
    ref(value);
  } else if (ref) {
    ref.current = value;
  }
};

export const useForkRef = <T extends HTMLElement | null = HTMLElement | null>(
  refA: AnyRef<T> | null,
  refB: AnyRef<T> | null,
): CallbackRef<T> | null => {
  return useMemo(() => {
    if (refA === null && refB === null) {
      return null;
    }

    return (refValue: T) => {
      setRef(refA, refValue);
      setRef(refB, refValue);
    };
  }, [refA, refB]);
};
