import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const PhoneIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    width="98px"
    height="98px"
    viewBox="0 0 98 98"
    ref={ref}
    {...props}
  >
    <g>
      <path
        fill="currentColor"
        d="M68,0H30c-5.5,0-10,4.5-10,10v78c0,5.5,4.5,10,10,10h38c5.5,0,10-4.5,10-10V10C78,4.5,73.5,0,68,0z M49,94   c-3.9,0-7-2.2-7-5c0-2.8,3.1-5,7-5c3.9,0,7,2.2,7,5C56,91.8,52.9,94,49,94z M70,79H28V13h42V79z"
      />
    </g>
  </svg>
));

PhoneIcon.displayName = 'PhoneIcon';
