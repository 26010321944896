import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const CC3DSecureIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 48 48"
    ref={ref}
    {...props}
  >
    <path xmlns="http://www.w3.org/2000/svg" d="m1 8h31v21h-31z" fill="#fff" />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m31.5 5.5h-28c-1.9 0-3.5 1.6-3.5 3.5v17.5c0 1.9 1.6 3.5 3.5 3.5h28c1.9 0 3.5-1.6 3.5-3.5v-17.5c0-1.9-1.6-3.5-3.5-3.5zm0 21h-28v-10.5h28zm0-15.7h-28v-1.8h28zm-24.5 8.9v1h1v-1zm6.3 2.1v1h2.1v-1h1v-1h1v-1h-2.1v1h-1v1zm4.2 1v-1h-1v1zm-5.3 0v-1h-2.1v1zm1.1-2.1h1v-1h-2.1v2.1h1zm-4.2 1.1h1v-1h1v-1h-2v1h-1.1v1h-1v1h2.1z"
      fill="#515151"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m34.8 2c.4.1.9.2 1.4.5 1.9 1 3.7 2 5.5 3.1 1.5.8 3 1.7 4.7 1.9.8.1 1.2.7 1.2 1.4-.2 3.3-.6 6.5-1.6 9.7-1.7 5.7-4.9 10.2-10 13.3-1 .6-1.7.6-2.7 0-5.9-3.7-9.2-9.2-10.6-15.9-.5-2.3-.6-4.7-.9-7.1-.1-.6.4-1.3 1-1.3 2.5-.2 4.4-1.7 6.5-2.9 1.4-.8 2.9-1.6 4.4-2.3.3-.2.7-.3 1.1-.4z"
      fill="#0521cc"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m35 2c-1 .1-.9.1-1.2.3-1.5.7-3 1.5-4.4 2.3-2.1 1.2-4 2.6-6.5 2.9-.6.1-1.1.7-1 1.3.3 2.4.4 4.8.9 7.1 1.4 6.7 4.8 12.2 10.7 15.9.5.3.5.4 1.5.4z"
      fill="#0623b2"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m48 42c0 2.2-1.8 4-4 4h-40c-2.2 0-4-1.8-4-4v-4c0-2.2 1.8-4 4-4h40c2.2 0 4 1.8 4 4z"
      fill="#515151"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m4.6 39.5h.6c.5 0 .8-.2.8-.7 0-.2-.1-.3-.2-.5-.1-.1-.3-.2-.5-.2s-.3.1-.5.2c-.1.1-.2.2-.2.4h-1.3c0-.3.1-.6.3-.8s.4-.4.7-.6c.3-.1.6-.2 1-.2.6 0 1.2.1 1.5.4s.6.7.6 1.2c0 .3-.1.5-.2.7-.2.2-.4.4-.7.5.3.1.6.3.7.5.2.2.3.5.3.8 0 .5-.2.9-.6 1.2s-.9.5-1.6.5c-.4 0-.7-.1-1.1-.2-.3-.1-.6-.3-.7-.6-.2-.3-.3-.5-.3-.9h1.4c0 .2.1.3.2.5.1.1.3.2.5.2s.4-.1.6-.2c.1-.1.2-.3.2-.5 0-.3-.1-.5-.2-.6s-.3-.2-.6-.2h-.7zm3.6 3.5v-5.8h1.9c.5 0 1 .1 1.4.3s.7.6 1 1c.2.4.3.9.4 1.4v.3c0 .5-.1 1-.3 1.4s-.5.8-1 1c-.4.2-.9.4-1.4.4zm1.4-4.7v3.7h.5c.4 0 .7-.1.9-.4s.3-.7.3-1.3v-.3c0-.6-.1-1-.3-1.3s-.5-.4-.9-.4zm8.9 3.2c0-.2-.1-.4-.2-.5s-.4-.2-.8-.4c-.4-.1-.7-.2-.9-.4-.8-.4-1.1-.9-1.1-1.5 0-.3.1-.6.3-.9.2-.2.5-.4.8-.6.3-.1.7-.2 1.2-.2.4 0 .8.1 1.1.2s.6.4.8.6c.2.3.3.6.3.9h-1.4c0-.2-.1-.4-.2-.6-.1-.1-.3-.2-.6-.2s-.4.1-.6.2c-.1.1-.2.3-.2.4s.1.3.2.4l.8.4c.4.1.7.3 1 .4.6.4.9.9.9 1.5 0 .5-.2.9-.6 1.2s-.9.4-1.6.4c-.5 0-.9-.1-1.3-.3s-.7-.4-.9-.7-.3-.6-.3-1h1.4c0 .3.1.5.2.7.2.1.4.2.8.2.2 0 .4 0 .5-.1.3.2.4 0 .4-.1zm5.7-1h-2.2v1.4h2.6v1.1h-4v-5.8h4v1.1h-2.6v1.2h2.2zm5.7.5c0 .4-.1.8-.3 1.1s-.5.6-.8.7c-.4.2-.8.3-1.2.3-.8 0-1.4-.2-1.8-.7s-.7-1.2-.7-2.1v-.3c0-.6.1-1.1.3-1.5s.5-.8.9-1 .8-.3 1.3-.3c.7 0 1.3.2 1.7.6s.7.9.7 1.5h-1.4c0-.4-.1-.6-.3-.8s-.4-.2-.8-.2c-.3 0-.6.1-.8.4s-.2.7-.3 1.3v.4c0 .6.1 1.1.2 1.3.2.3.4.4.8.4.3 0 .6-.1.7-.2.2-.2.3-.4.3-.7h1.5zm5.3-3.8v3.8c0 .4-.1.8-.3 1.1s-.4.6-.8.7-.8.2-1.2.2c-.7 0-1.3-.2-1.7-.6s-.6-.9-.6-1.5v-3.8h1.4v3.9c0 .6.3 1 .9 1 .3 0 .5-.1.7-.2.1-.2.2-.4.2-.8v-3.8zm3 3.8h-.8v2h-1.4v-5.8h2.3c.7 0 1.2.2 1.6.5s.6.7.6 1.3c0 .4-.1.7-.2 1-.2.3-.4.5-.8.6l1.2 2.4h-1.5zm-.8-1.1h.9c.3 0 .5-.1.6-.2s.2-.3.2-.6-.1-.4-.2-.6-.3-.2-.6-.2h-.9zm7.6.6h-2.2v1.4h2.6v1.1h-4v-5.8h4v1.1h-2.6v1.2h2.2z"
      fill="#fff"
    />
  </svg>
));

CC3DSecureIcon.displayName = 'CC3DSecureIcon';
