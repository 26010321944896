import {
  type AuthProviderConfig,
  initReactQueryAuth,
} from '@/shared/lib/react-query-auth.lib';
import type { User } from '@/features/auth/types';
import { getUser } from '@/features/auth/api/queries';
import { sessionLogin } from '@/features/auth/api/mutations';

const authConfig: AuthProviderConfig<User> = {
  loadUser: getUser,
  sessionLoginFn: sessionLogin,
  // @ts-expect-error not implemented yet
  loginFn: () => Promise.resolve({ currency: 'USD' }),
  logoutFn: () => Promise.resolve(),
  // @ts-expect-error not implemented yet
  registerFn: () => Promise.resolve({ currency: 'USD' }),
};

export const { AuthProvider, useAuth } = initReactQueryAuth<
  User,
  unknown,
  unknown
>(authConfig);
