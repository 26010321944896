import { forwardRef, type ComponentPropsWithoutRef, type Ref } from 'react';

import { useForkRef } from '@/shared/hooks';
import { TextField } from '@/ui';

type Props = ComponentPropsWithoutRef<typeof TextField> & {
  inputRef?: Ref<HTMLInputElement | null>;
};

export const GooglePlacesAutocompleteTextField = forwardRef<
  HTMLInputElement,
  Props
>(({ inputRef: externalInputRef, ...props }, ref) => {
  const inputRef = useForkRef(ref, externalInputRef ?? null);

  return <TextField {...props} ref={inputRef} />;
});

GooglePlacesAutocompleteTextField.displayName =
  'GooglePlacesAutocompleteTextField';
