import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';

import { App } from './App';
import { reportWebVitals } from './reportWebVitals';

import { AppProviders } from '@/providers/app';

import './index.css';

// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const container = document.getElementById('root')!;
const root = createRoot(container);

const renderApp = () =>
  root.render(
    <StrictMode>
      <AppProviders>
        <App />
      </AppProviders>
    </StrictMode>,
  );

renderApp();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
