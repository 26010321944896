import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const CCMasterCardIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 40.667"
    ref={ref}
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m2.134 39.833c-.901 0-1.634-.732-1.634-1.633v-35.732c0-.901.733-1.635 1.634-1.635h59.732c.9 0 1.634.733 1.634 1.635v35.733c0 .9-.733 1.633-1.634 1.633h-59.732z"
      fill="#f9f9f9"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m61.866 1.333c.625 0 1.134.509 1.134 1.135v35.733c0 .625-.509 1.133-1.134 1.133h-59.732c-.625-.001-1.134-.508-1.134-1.133v-35.733c0-.625.509-1.135 1.134-1.135zm0-1h-59.732c-1.178 0-2.134.955-2.134 2.135v35.733c0 1.178.956 2.133 2.134 2.133h59.732c1.177 0 2.134-.955 2.134-2.133v-35.733c0-1.179-.957-2.135-2.134-2.135z"
      fill="#c6c6c5"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m39.682 31.815.591-.015.584-.043.575-.073.568-.101.555-.126.545-.157.535-.183.521-.203.512-.234.495-.252.483-.278.467-.298.45-.321.433-.341.417-.36.401-.384.383-.398.362-.42.339-.434.321-.45.301-.467.276-.479.252-.498.233-.509.203-.524.182-.534.158-.547.127-.554.1-.569.074-.573.042-.584.017-.593-.017-.589-.042-.581-.074-.573-.1-.563-.127-.552-.158-.543-.182-.535-.203-.519-.233-.507-.252-.496-.276-.476-.301-.467-.321-.45-.339-.43-.362-.419-.383-.399-.401-.38-.417-.36-.433-.341-.45-.321-.467-.299-.483-.274-.495-.252-.512-.233-.521-.205-.535-.181-.545-.156-.555-.128-.568-.1-.575-.072-.584-.044-.591-.017-.589.017-.586.044-.573.072-.567.1-.553.128-.544.156-.534.181-.52.205-.508.233-.493.252-.481.274-.464.299-.448.321-.434.341-.415.36-.399.38-.379.399-.362.419-.34.43-.318.45-.298.467-.274.476-.256.496-.229.507-.205.519-.179.535-.155.543-.126.552-.101.563-.074.573-.044.581-.015.589.015.593.044.584.074.573.101.569.126.554.155.547.179.534.205.524.229.509.256.498.274.479.298.467.318.45.34.434.362.42.379.398.399.384.415.36.434.341.448.321.464.298.481.278.493.252.508.234.52.203.534.183.544.157.553.126.567.101.573.073.586.043z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m24.32 31.815.588-.015.584-.043.574-.073.566-.101.553-.126.547-.157.531-.183.522-.203.507-.234.493-.252.481-.278.463-.298.449-.321.434-.341.414-.36.399-.384.38-.398.361-.42.341-.434.317-.45.299-.467.275-.479.256-.498.227-.509.208-.524.177-.534.153-.547.128-.554.101-.569.073-.573.045-.584.015-.593-.015-.589-.045-.581-.073-.573-.101-.563-.128-.552-.153-.543-.177-.535-.208-.519-.227-.507-.256-.496-.275-.476-.299-.467-.317-.45-.341-.43-.361-.419-.38-.399-.399-.38-.414-.36-.434-.341-.449-.321-.463-.299-.481-.274-.493-.252-.507-.233-.522-.205-.531-.181-.547-.156-.553-.128-.566-.1-.574-.072-.584-.044-.588-.017-.593.017-.586.044-.572.072-.57.1-.554.128-.547.156-.534.181-.522.205-.512.233-.496.252-.479.274-.469.299-.449.321-.434.341-.419.36-.398.38-.384.399-.36.419-.34.43-.322.45-.298.467-.279.476-.251.496-.234.507-.204.519-.183.535-.155.543-.128.552-.1.563-.074.573-.043.581-.015.589.015.593.043.584.074.573.1.569.128.554.155.547.183.534.204.524.234.509.251.498.279.479.298.467.322.45.34.434.36.42.384.398.398.384.419.36.434.341.449.321.469.298.479.278.496.252.512.234.522.203.534.183.547.157.554.126.57.101.572.073.586.043z"
      fill="#ec1c2e"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m30.656 13.255h6.101v-.624h-5.575z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m29.741 14.642h7.016v-.623h-6.648z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m29.06 16.026h7.697v-.624h-7.426z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m28.591 17.412h8.166v-.624h-7.972z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m28.862 24.115h7.913v-.625h-8.106z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m29.448 25.498h7.327v-.623h-7.618z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m30.267 26.88h6.508v-.623h-6.898z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m31.399 28.267h5.376v-.627h-5.921z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m28.316 18.793h8.441v-.623h-8.32z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m33.46 22.731h3.22v-.625h-3.103z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m33.717 21.347h2.963v-.627h-2.848z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m28.104 20.72h.836v.627h-.798z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m26.542 19.964-.054-.004-.054-.008-.05-.007-.051-.008-.046-.008-.051-.009-.049-.006-.055-.007-.054-.008-.062-.004-.07-.005-.073-.004h-.081l-.089.004-.101.009-.113.008-.077.01-.074.012-.067.023-.057.022-.054.029-.051.032-.038.038-.035.034-.023.044-.02.042-.008.046v.048l.012.049.016.05.031.051.038.05.078.073.093.059.107.049.118.044.124.038.127.042.128.047.124.051.116.067.108.08.097.097.082.119.062.149.038.174.016.209-.016.244-.026.179-.047.161-.062.146-.078.129-.088.111-.102.099-.108.084-.119.073-.125.058-.127.05-.133.041-.135.028-.131.024-.127.013-.129.01-.119.005h-.168l-.155-.005h-.136l-.127-.004-.116-.006-.105-.009-.093-.008-.089-.009-.081-.011-.074-.013-.074-.011-.065-.02-.066-.018-.061-.02-.063-.023-.065-.026.173-.854.035.005.043.015.054.017.067.011.069.018.077.016.085.015.092.019.098.014.101.017.105.012.107.009.113.005.113.004h.112l.112-.004.112-.022.089-.033.07-.04.055-.054.038-.054.031-.063.021-.062.014-.058.009-.114-.036-.095-.066-.078-.097-.067-.116-.056-.136-.059-.151-.055-.15-.06-.156-.071-.146-.082-.136-.099-.124-.119-.097-.149-.07-.174-.035-.209.004-.248.02-.152.027-.143.039-.131.046-.123.061-.117.071-.101.081-.098.097-.084.113-.074.124-.063.138-.057.155-.043.176-.033.188-.029.21-.015.228-.005h.143l.132.005.121.005.107.003.097.007.089.008.078.008.07.009.067.006.053.012.05.008.048.008.039.007.032.004.035.008.031.004z"
      fill="#fff"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m26.542 19.964-.054-.004-.054-.008-.05-.007-.051-.008-.046-.008-.051-.009-.049-.006-.055-.007-.054-.008-.062-.004-.07-.005-.073-.004h-.081l-.089.004-.101.009-.113.008-.077.01-.074.012-.067.023-.057.022-.054.029-.051.032-.038.038-.035.034-.023.044-.02.042-.008.046v.048l.012.049.016.05.031.051.038.05.078.073.093.059.107.049.118.044.124.038.127.042.128.047.124.051.116.067.108.08.097.097.082.119.062.149.038.174.016.209-.016.244-.026.179-.047.161-.062.146-.078.129-.088.111-.102.099-.108.084-.119.073-.125.058-.127.05-.133.041-.135.028-.131.024-.127.013-.129.01-.119.005h-.168l-.155-.005h-.136l-.127-.004-.116-.006-.105-.009-.093-.008-.089-.009-.081-.011-.074-.013-.074-.011-.065-.02-.066-.018-.061-.02-.063-.023-.065-.026.173-.854.035.005.043.015.054.017.067.011.069.018.077.016.085.015.092.019.098.014.101.017.105.012.107.009.113.005.113.004h.112l.112-.004.112-.022.089-.033.07-.04.055-.054.038-.054.031-.063.021-.062.014-.058.009-.114-.036-.095-.066-.078-.097-.067-.116-.056-.136-.059-.151-.055-.15-.06-.156-.071-.146-.082-.136-.099-.124-.119-.097-.149-.07-.174-.035-.209.004-.248.02-.152.027-.143.039-.131.046-.123.061-.117.071-.101.081-.098.097-.084.113-.074.124-.063.138-.057.155-.043.176-.033.188-.029.21-.015.228-.005h.143l.132.005.121.005.107.003.097.007.089.008.078.008.07.009.067.006.053.012.05.008.048.008.039.007.032.004.035.008.031.004z"
      fill="none"
      stroke="#fff"
      strokeMiterlimit="2.6131"
      strokeWidth=".1102"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m27.46 18.132h1.074l-.159.937h.646l-.139.876-.682-.02-.372 2.16-.003.081.019.065.039.05.05.04.07.029.077.021.086.017.093.008.084-.004.078-.008.069-.013.062-.013.052-.017.035-.009.023-.011.007-.002-.135.722-.028.016-.027.019-.026.013-.029.017-.026.009-.032.011-.034.014-.035.012-.038.012-.04.007-.047.007-.045.008-.049.008-.06.009-.063.005-.064.008h-.295l-.156-.009-.135-.019-.123-.021-.104-.027-.091-.034-.072-.039-.066-.048-.052-.051-.037-.052-.028-.066-.019-.065-.011-.077-.004-.08v-.084l.01-.089.013-.097z"
      fill="#fff"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m27.46 18.132h1.074l-.159.937h.646l-.139.876-.682-.02-.372 2.16-.003.081.019.065.039.05.05.04.07.029.077.021.086.017.093.008.084-.004.078-.008.069-.013.062-.013.052-.017.035-.009.023-.011.007-.002-.135.722-.028.016-.027.019-.026.013-.029.017-.026.009-.032.011-.034.014-.035.012-.038.012-.04.007-.047.007-.045.008-.049.008-.06.009-.063.005-.064.008h-.295l-.156-.009-.135-.019-.123-.021-.104-.027-.091-.034-.072-.039-.066-.048-.052-.051-.037-.052-.028-.066-.019-.065-.011-.077-.004-.08v-.084l.01-.089.013-.097z"
      fill="none"
      stroke="#fff"
      strokeMiterlimit="2.6131"
      strokeWidth=".1102"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m29.877 21.406-.012.065-.004.068.008.074.017.073.026.073.032.073.041.072.047.067.054.066.058.056.063.059.068.047.072.041.075.037.079.023.077.019.1.014.098.013.1.012.102.007.1.004h.104l.101-.004.106-.007.103-.017.105-.02.108-.021.105-.035.109-.042.112-.048.109-.06.111-.067-.154.952-.063.033-.061.028-.062.022-.057.027-.059.016-.062.022-.062.016-.066.011-.07.011-.078.008-.081.009-.088.005-.101.004h-.105l-.121.004h-.128l-.189-.004-.192-.018-.195-.027-.194-.048-.189-.058-.182-.071-.175-.098-.162-.113-.152-.131-.131-.158-.113-.181-.093-.203-.066-.23-.041-.257-.013-.287.02-.319.016-.123.022-.143.031-.15.043-.165.058-.165.07-.168.089-.17.108-.162.125-.156.151-.146.169-.132.194-.116.224-.097.253-.07.278-.046.315-.017.151.005.159.015.159.029.159.037.154.058.151.071.139.091.128.11.117.133.097.154.076.178.055.205.03.231.005.264-.027.294-.059.326-.018.235h-2.824l.177-.761h1.697l.009-.097-.005-.093-.008-.086-.015-.077-.028-.074-.027-.067-.037-.057-.039-.054-.047-.046-.05-.038-.058-.036-.058-.03-.062-.024-.062-.015-.066-.013-.07-.007-.074.003-.069.012-.066.016-.066.024-.066.03-.057.036-.059.042-.051.046-.05.054-.048.057-.041.063-.034.066-.036.07-.027.077-.024.079-.015.079z"
      fill="none"
      stroke="#fff"
      strokeMiterlimit="2.6131"
      strokeWidth=".1102"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m39.113 19.301-.049-.025-.048-.025-.056-.027-.058-.027-.058-.024-.065-.021-.066-.025-.068-.024-.077-.018-.074-.016-.081-.015-.079-.012-.091-.006-.087-.008h-.093-.097-.099l-.099.008-.107.009-.106.021-.11.031-.106.042-.11.054-.109.069-.104.087-.103.1-.097.124-.091.143-.084.17-.082.194-.075.217-.061.247-.023.14-.015.135-.008.138.004.13.012.131.024.125.037.115.042.118.059.102.068.103.084.088.091.084.106.068.119.059.133.048.147.038.049.009.057.011.071.008.079.009.084.005h.087.099l.096-.005.102-.013.1-.017.096-.029.097-.029.097-.044.09-.05.082-.063.075-.07-.173 1.037-.022.017-.024.022-.032.028-.034.023-.041.033-.057.028-.062.026-.067.021-.089.029-.101.019-.118.015-.134.017-.151.005-.175.004-.196-.004-.219-.009-.179-.021-.179-.038-.175-.057-.179-.08-.17-.091-.164-.116-.153-.136-.145-.155-.129-.173-.105-.196-.096-.215-.068-.239-.047-.259-.017-.273.008-.302.045-.322.029-.159.036-.155.037-.151.039-.144.043-.134.051-.136.051-.128.055-.119.056-.121.058-.113.065-.108.071-.1.07-.098.078-.093.076-.085.08-.082.091-.076.088-.074.095-.067.095-.061.107-.058.102-.05.109-.047.111-.042.122-.039.121-.032.123-.026.131-.023.131-.02.141-.015.138-.008.15-.004.125.004.119.008.115.01.114.017.109.014.106.026.101.017.091.024.092.022.078.025.073.023.061.022.054.021.046.016.034.015.025.006z"
      fill="#fff"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m39.113 19.301-.049-.025-.048-.025-.056-.027-.058-.027-.058-.024-.065-.021-.066-.025-.068-.024-.077-.018-.074-.016-.081-.015-.079-.012-.091-.006-.087-.008h-.093-.097-.099l-.099.008-.107.009-.106.021-.11.031-.106.042-.11.054-.109.069-.104.087-.103.1-.097.124-.091.143-.084.17-.082.194-.075.217-.061.247-.023.14-.015.135-.008.138.004.13.012.131.024.125.037.115.042.118.059.102.068.103.084.088.091.084.106.068.119.059.133.048.147.038.049.009.057.011.071.008.079.009.084.005h.087.099l.096-.005.102-.013.1-.017.096-.029.097-.029.097-.044.09-.05.082-.063.075-.07-.173 1.037-.022.017-.024.022-.032.028-.034.023-.041.033-.057.028-.062.026-.067.021-.089.029-.101.019-.118.015-.134.017-.151.005-.175.004-.196-.004-.219-.009-.179-.021-.179-.038-.175-.057-.179-.08-.17-.091-.164-.116-.153-.136-.145-.155-.129-.173-.105-.196-.096-.215-.068-.239-.047-.259-.017-.273.008-.302.045-.322.029-.159.036-.155.037-.151.039-.144.043-.134.051-.136.051-.128.055-.119.056-.121.058-.113.065-.108.071-.1.07-.098.078-.093.076-.085.08-.082.091-.076.088-.074.095-.067.095-.061.107-.058.102-.05.109-.047.111-.042.122-.039.121-.032.123-.026.131-.023.131-.02.141-.015.138-.008.15-.004.125.004.119.008.115.01.114.017.109.014.106.026.101.017.091.024.092.022.078.025.073.023.061.022.054.021.046.016.034.015.025.006z"
      fill="none"
      stroke="#fff"
      strokeMiterlimit="2.6131"
      strokeWidth=".1102"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m48.65 23.216h-1.072l.078-.427v-.041l-.062.08-.067.068-.064.062-.07.053-.071.05-.07.045-.068.034-.077.035-.073.028-.071.018-.074.019-.078.011-.079.012-.074.008-.075.004h-.077l-.203-.016-.186-.043-.17-.064-.156-.091-.137-.11-.125-.132-.11-.144-.092-.162-.077-.176-.062-.179-.042-.192-.033-.197-.011-.197.003-.201.021-.2.033-.196.06-.228.068-.216.083-.198.098-.186.098-.174.118-.156.119-.139.129-.129.134-.112.145-.096.146-.086.149-.065.152-.056.156-.034.155-.023.159-.008.112.004.108.012.102.018.092.028.09.031.079.035.075.043.069.044.063.045.057.05.052.051.047.049.037.053.037.049.03.046.023.046.272-1.521h1.052z"
      fill="#fff"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m48.65 23.216h-1.072l.078-.427v-.041l-.062.08-.067.068-.064.062-.07.053-.071.05-.07.045-.068.034-.077.035-.073.028-.071.018-.074.019-.078.011-.079.012-.074.008-.075.004h-.077l-.203-.016-.186-.043-.17-.064-.156-.091-.137-.11-.125-.132-.11-.144-.092-.162-.077-.176-.062-.179-.042-.192-.033-.197-.011-.197.003-.201.021-.2.033-.196.06-.228.068-.216.083-.198.098-.186.098-.174.118-.156.119-.139.129-.129.134-.112.145-.096.146-.086.149-.065.152-.056.156-.034.155-.023.159-.008.112.004.108.012.102.018.092.028.09.031.079.035.075.043.069.044.063.045.057.05.052.051.047.049.037.053.037.049.03.046.023.046.272-1.521h1.052z"
      fill="none"
      stroke="#fff"
      strokeMiterlimit="2.6131"
      strokeWidth=".1102"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m46.896 22.281.108-.008.1-.025.095-.025.087-.045.082-.046.077-.056.076-.067.065-.071.059-.076.052-.085.05-.091.043-.09.04-.098.029-.1.03-.106.017-.104.02-.106.012-.114v-.106l-.002-.108-.014-.099-.016-.099-.026-.092-.032-.089-.037-.078-.047-.073-.049-.062-.059-.055-.066-.046-.069-.03-.076-.02-.082-.008-.103.008-.098.016-.088.03-.09.039-.078.051-.076.061-.064.067-.069.074-.056.083-.054.091-.054.092-.041.101-.037.103-.032.104-.03.114-.024.113-.021.17.009.185.028.189.051.182.078.161.099.129.127.082z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m23.111 22.358-.156.858h-.975l.027-.159.02-.141.015-.111.015-.057-.054.061-.062.064-.073.057-.081.055-.093.056-.097.042-.102.047-.104.041-.113.036-.111.028-.113.02-.116.016-.112.01-.112.004-.109-.014-.105-.016-.136-.034-.124-.041-.107-.05-.097-.054-.085-.065-.077-.069-.063-.075-.054-.075-.047-.084-.038-.085-.031-.082-.024-.086-.015-.09-.012-.088-.008-.087v-.084l.012-.151.023-.144.039-.136.05-.133.062-.123.069-.111.083-.107.093-.099.096-.089.109-.081.112-.073.116-.064.124-.051.129-.044.13-.035.136-.023h.008l.019-.005.028-.004.042-.003.047-.007.054-.004.058-.008.066-.009.066-.005.066-.009.069-.004.061-.008.063-.004.058-.004.055-.003h.041.091l.093.003.084.004h.085l.07.004.054.005.039.003h.012l.007-.028.024-.069.019-.08.008-.074-.017-.085-.034-.08-.046-.069-.062-.058-.074-.049-.08-.035-.094-.024-.1-.008-.11-.005-.116-.003h-.119l-.124.003-.128.009-.124.012-.124.011-.12.017-.116.014-.113.02-.101.019-.092.019-.085.025-.071.022-.053.027-.044.023.276-.933.085-.036.085-.03.093-.027.093-.019.094-.02.1-.015.101-.016.104-.006.109-.008.112-.005.117-.005h.12.123.128l.135.005.136.005.109.008.116.018.12.028.124.034.12.046.117.055.116.067.108.072.096.087.086.093.078.102.057.117.039.124.022.132v.148l-.022.154z"
      fill="#fff"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m23.111 22.358-.156.858h-.975l.027-.159.02-.141.015-.111.015-.057-.054.061-.062.064-.073.057-.081.055-.093.056-.097.042-.102.047-.104.041-.113.036-.111.028-.113.02-.116.016-.112.01-.112.004-.109-.014-.105-.016-.136-.034-.124-.041-.107-.05-.097-.054-.085-.065-.077-.069-.063-.075-.054-.075-.047-.084-.038-.085-.031-.082-.024-.086-.015-.09-.012-.088-.008-.087v-.084l.012-.151.023-.144.039-.136.05-.133.062-.123.069-.111.083-.107.093-.099.096-.089.109-.081.112-.073.116-.064.124-.051.129-.044.13-.035.136-.023h.008l.019-.005.028-.004.042-.003.047-.007.054-.004.058-.008.066-.009.066-.005.066-.009.069-.004.061-.008.063-.004.058-.004.055-.003h.041.091l.093.003.084.004h.085l.07.004.054.005.039.003h.012l.007-.028.024-.069.019-.08.008-.074-.017-.085-.034-.08-.046-.069-.062-.058-.074-.049-.08-.035-.094-.024-.1-.008-.11-.005-.116-.003h-.119l-.124.003-.128.009-.124.012-.124.011-.12.017-.116.014-.113.02-.101.019-.092.019-.085.025-.071.022-.053.027-.044.023.276-.933.085-.036.085-.03.093-.027.093-.019.094-.02.1-.015.101-.016.104-.006.109-.008.112-.005.117-.005h.12.123.128l.135.005.136.005.109.008.116.018.12.028.124.034.12.046.117.055.116.067.108.072.096.087.086.093.078.102.057.117.039.124.022.132v.148l-.022.154z"
      fill="none"
      stroke="#fff"
      strokeMiterlimit="2.6131"
      strokeWidth=".1102"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m22.177 21.347-.004-.019-.015-.02-.023-.012-.038-.007-.044-.006-.05-.006h-.058-.058l-.061.006-.066.006-.062.007-.062.008-.058.009-.056.011-.045.011-.043.012-.027.007-.031.013-.043.013-.042.022-.051.021-.05.03-.054.025-.051.031-.054.037-.05.041-.047.045-.043.046-.038.053-.027.054-.023.061-.012.064-.003.131.018.103.039.079.05.058.058.038.067.022.062.013.061.004.117-.004.104-.013.101-.015.089-.026.077-.023.073-.037.066-.033.059-.041.051-.041.045-.045.039-.043.031-.047.026-.048.026-.046.018-.042.012-.043.031-.11.027-.129.023-.123z"
      fill="#ec1c2e"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m14.961 23.216h-1.052l.879-5.084h1.831l.176 3.018 1.266-3.018h1.913l-.898 5.084h-1.055l.667-3.799h-.042l-1.577 3.799h-1.189l-.008-.159-.027-.421-.034-.599-.04-.69-.038-.692-.035-.61-.027-.441-.008-.187h-.012-.023-.023z"
      fill="#fff"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m14.961 23.216h-1.052l.879-5.084h1.831l.176 3.018 1.266-3.018h1.913l-.898 5.084h-1.055l.667-3.799h-.042l-1.577 3.799h-1.189l-.008-.159-.027-.421-.034-.599-.04-.69-.038-.692-.035-.61-.027-.441-.008-.187h-.012-.023-.023z"
      fill="none"
      stroke="#fff"
      strokeMiterlimit="2.6131"
      strokeWidth=".1102"
    />
    <g xmlns="http://www.w3.org/2000/svg" fill="#fff">
      <path d="m42.335 22.358-.16.858h-.972l.008-.159.005-.138v-.104l.005-.046-.05.059-.058.056-.071.054-.074.054-.081.052-.096.045-.096.047-.097.039-.105.032-.108.033-.108.015-.11.016-.11.01-.11.004-.103-.014-.108-.016-.138-.034-.123-.041-.109-.05-.1-.054-.084-.065-.077-.069-.061-.075-.054-.075-.045-.084-.037-.085-.029-.082-.022-.086-.013-.09-.011-.088-.009-.087v-.084l.009-.151.021-.144.037-.136.048-.133.059-.123.074-.111.082-.107.088-.099.102-.089.103-.081.114-.073.121-.064.118-.051.128-.044.129-.035.131-.023h.007l.021-.005.026-.004.039-.003.051-.007.053-.004.058-.008.062-.009.065-.005.073-.009.065-.004.065-.008.062-.004.054-.004.054-.003h.041.095l.092.003.091.004h.082l.075.004.061.005.038.003h.013l.008-.028.012-.069.012-.08.007-.074-.015-.085-.03-.08-.052-.069-.059-.058-.07-.049-.078-.035-.086-.024-.093-.008-.11-.005-.116-.003h-.12l-.124.003-.127.009-.125.012-.123.011-.121.017-.115.014-.111.02-.103.019-.092.019-.087.025-.069.022-.056.027-.041.023.272-.933.086-.036.09-.03.085-.027.095-.019.096-.02.099-.015.099-.016.104-.006.11-.008.112-.005.114-.005h.119.119.124l.132.005.133.005.106.008.116.018.121.028.125.034.121.046.12.055.115.067.107.072.1.087.088.093.076.102.058.117.041.124.03.132v.148l-.02.154z" />
      <path d="m42.175 23.244.022-.023.158-.859-.044-.008-.159.858.023-.024v.056l.018-.004.004-.019z" />
      <path d="m41.18 23.216.023.028h.972v-.056h-.972l.022.028h-.045l-.005.028h.028z" />
      <path d="m41.241 22.785-.034-.034-.016.064-.003.104v.138l-.008.159h.045l.007-.159.009-.138-.005-.104v-.03l-.033-.034z" />
      <path d="m39.734 23.281.11.015.103.009.11-.005.11-.004.114-.015.11-.022.107-.028.106-.032.1-.039.101-.045.093-.048.079-.056.084-.053.068-.054.062-.063.05-.056-.038-.034-.051.058-.054.056-.069.054-.073.056-.083.044-.092.048-.093.048-.097.037-.101.028-.108.032-.109.023-.106.013-.11.007-.11.004-.103-.011-.102-.013z" />
      <path d="m38.796 22.106.004.084.009.087.011.092.017.089.017.086.033.088.033.089.051.079.056.084.062.074.075.075.09.06.1.063.115.051.125.037.14.037.008-.046-.14-.036-.12-.039-.103-.05-.102-.057-.082-.061-.068-.067-.063-.071-.054-.075-.041-.08-.035-.084-.032-.078-.019-.086-.016-.089-.013-.085-.008-.087.004-.084z" />
      <path d="m40.188 20.619-.135.023-.134.034-.127.044-.124.054-.12.061-.114.077-.106.084-.101.091-.092.097-.082.108-.078.118-.059.127-.049.132-.034.138-.024.148-.013.151h.054l.004-.151.025-.14.033-.133.053-.13.056-.12.072-.108.08-.11.085-.095.099-.084.107-.082.106-.073.121-.061.118-.053.129-.042.123-.035.127-.024z" />
      <path d="m40.984 20.537h-.041l-.054.007-.054.005-.062.004-.065.007-.065.005-.073.007-.065.009-.062.007-.058.009-.053.002-.051.008-.039.004-.03.004-.017.004h-.007v.046h.007l.024-.003.023-.003.039-.004.051-.009.053-.004.058-.009.062-.007.065-.007.073-.008.065-.004.065-.008.062-.002.054-.005h.054.041z" />
      <path d="m41.509 20.581.022-.021h-.013l-.038-.004-.061-.003-.075-.009h-.082-.091l-.092-.007h-.095v.055h.095.092l.091.007h.082.075l.061.003.038.005h.013l.024-.019-.024.019.02.004.004-.023z" />
      <path d="m41.547 20.333h-.005l-.003.074-.01.076-.011.07-.009.028.046.007.008-.028.011-.07.013-.083.009-.074h-.002z" />
      <path d="m41.087 19.949v.003l.088.005.083.022.077.035.065.047.054.054.046.066.031.069.016.083h.047l-.015-.09-.032-.084-.055-.074-.06-.063-.075-.053-.084-.036-.089-.021-.097-.013v.004z" />
      <path d="m39.424 20.134.04.025.038-.021.051-.027.065-.023.086-.022.092-.021.1-.018.114-.021.111-.014.121-.018.123-.01.125-.012.127-.007h.124.12.116l.11.004v-.046l-.11-.004-.116-.009h-.12l-.124.009-.127.008-.125.01-.123.012-.121.016-.119.015-.111.019-.103.021-.093.019-.086.022-.073.025-.057.026-.045.027.037.024z" />
      <path d="m39.713 19.182-.018.02-.271.932.047.009.271-.934-.015.019-.014-.046-.013.004-.005.016z" />
      <path d="m41.436 19.004-.133-.007-.132-.005h-.124-.119-.119l-.114.008-.112.004-.11.008-.104.006-.103.018-.099.014-.095.019-.095.02-.09.027-.091.031-.083.035.014.046.086-.035.089-.031.082-.028.096-.018.098-.019.095-.015.096-.016.104-.008.11-.008.112-.005h.114.119.119.124l.132.005h.133z" />
      <path d="m42.764 20.317.017-.158v-.148l-.025-.136-.042-.128-.062-.12-.08-.108-.093-.098-.094-.084-.114-.075-.115-.069-.125-.054-.122-.046-.127-.035-.121-.026-.119-.02-.106-.008v.046l.106.008.114.019.121.026.119.036.122.047.115.054.115.061.104.074.098.085.083.089.078.102.054.112.045.12.026.128v.148l-.018.15z" />
      <path d="m42.355 22.362.409-2.045-.046-.008-.407 2.045z" />
    </g>
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m41.396 21.347-.007-.023-.016-.02-.029-.012-.035-.009-.043-.006-.05-.004h-.06l-.058.004-.064.01-.061.005-.062.008-.059.008-.056.012-.05.008-.051.011-.039.008-.026.002-.032.013-.037.013-.043.014-.05.023-.049.024-.054.026-.051.036-.048.037-.05.041-.045.043-.041.05-.036.054-.028.058-.022.06-.012.069-.003.131.017.103.034.079.046.058.058.038.066.022.064.013.068.004.116-.004.106-.013.104-.02.088-.025.085-.031.075-.034.069-.041.062-.046.056-.042.045-.052.041-.047.037-.048.024-.045.023-.048.014-.047.012-.042.015-.107.019-.114.016-.108z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m49.374 22.686.009-.088.028-.106.058-.092.063-.081.081-.068.096-.044.1-.032.107-.009.109.009.101.032.093.044.081.068.066.081.054.092.032.106.009.088h-.117l-.003-.071-.027-.082-.037-.078-.05-.067-.063-.055-.071-.037-.086-.027-.091-.01-.087.01-.086.027-.074.037-.064.055-.048.067-.037.078-.025.082-.009.071z"
      fill="#fff"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m49.723 22.686v-.231h.213l.046.003.043.006.032.015.027.013.018.023.016.026.008.024.005.028-.005.035-.008.028-.013.022-.007.008h-.162l.016-.004.027-.004.023-.005.019-.009.015-.012.008-.012.009-.019v-.028l-.005-.017-.008-.019-.009-.007-.011-.006-.022-.003-.019-.004h-.019-.024-.116v.149z"
      fill="#fff"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m49.374 22.686-.003.022.012.113.028.104.058.094.063.079.081.066.096.048.1.032.107.011.109-.011.101-.032.093-.048.081-.066.066-.079.054-.094.032-.104.012-.113-.003-.022h-.117l.002.022-.005.092-.027.088-.037.072-.05.067-.063.056-.071.04-.086.029-.091.008-.087-.008-.086-.029-.074-.04-.064-.056-.048-.067-.037-.072-.025-.088-.009-.092v-.022z"
      fill="#fff"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m49.723 22.686v.239h.097v-.194h.096l.115.194h.078l-.115-.194h.027l.027-.01.024-.009.021-.013.005-.013h-.162l-.02.004h-.096v-.004z"
      fill="#fff"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m49.371 22.708.012-.11.028-.106.058-.092.063-.081.081-.067.096-.045.1-.029.107-.012.109.012.101.029.093.045.081.067.066.081.054.092.032.106.012.11-.012.111-.032.105-.054.093-.066.08-.081.065-.093.049-.101.031-.109.012-.107-.012-.1-.031-.096-.049-.081-.065-.063-.079-.058-.094-.028-.104zm.449.217h-.1v-.473h.215l.047.004.043.008.031.015.026.013.019.023.017.025.008.023.005.029-.005.033-.008.027-.013.021-.014.021-.021.017-.023.008-.025.01h-.027l.115.193h-.078l-.115-.193h-.097zm.096-.235.036-.008.024-.004.024-.005.021-.01.015-.013.008-.012.009-.021v-.025l-.005-.02-.008-.019-.009-.007-.014-.006-.021-.004-.021-.004h-.015-.024-.116v.152h.096m0 .476.091-.008.086-.029.071-.039.063-.057.053-.064.037-.073.024-.087.005-.092-.005-.096-.024-.082-.037-.078-.053-.065-.063-.054-.071-.039-.086-.026-.091-.011-.087.011-.086.026-.074.039-.063.054-.049.065-.037.078-.024.082-.009.096.009.092.024.087.037.073.049.064.063.057.074.039.086.029z"
      fill="none"
      stroke="#fff"
      strokeMiterlimit="2.6131"
      strokeWidth=".1102"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m42.624 23.216h1.094l.389-2.203.016-.085.023-.085.023-.085.032-.086.037-.084.046-.083.053-.076.061-.074.071-.065.075-.064.091-.058.099-.045.112-.038.125-.032.134-.022.153-.009h.055.031.01l.175-.994-.11.008-.103.009-.097.021-.093.027-.086.031-.08.038-.078.043-.073.046-.071.055-.066.054-.063.061-.06.062-.059.066-.056.075-.059.069-.055.078.118-.702h-1.09z"
      fill="#fff"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m42.624 23.216h1.094l.389-2.203.016-.085.023-.085.023-.085.032-.086.037-.084.046-.083.053-.076.061-.074.071-.065.075-.064.091-.058.099-.045.112-.038.125-.032.134-.022.153-.009h.055.031.01l.175-.994-.11.008-.103.009-.097.021-.093.027-.086.031-.08.038-.078.043-.073.046-.071.055-.066.054-.063.061-.06.062-.059.066-.056.075-.059.069-.055.078.118-.702h-1.09z"
      fill="none"
      stroke="#fff"
      strokeMiterlimit="2.6131"
      strokeWidth=".1102"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m32.368 23.216h1.092.019l.373-2.203.014-.085.021-.08.017-.082.03-.077.029-.078.039-.074.046-.069.054-.066.062-.059.07-.056.086-.048.092-.043.109-.034.122-.032.13-.019.15-.012h.013l.016.004.017.004h.019l.022.004.015.004.009.004h.005l.005-.016.013-.046.013-.07.023-.084.024-.102.029-.108.034-.109.036-.109.029-.072.038-.074.039-.074.039-.065.035-.059.027-.046.02-.027.008-.013h-.004l-.015-.003-.021-.004-.024-.002h-.03l-.029-.004-.028-.004h-.027l-.119.008-.11.009-.101.021-.093.023-.088.031-.082.04-.075.037-.07.047-.064.049-.063.058-.058.061-.057.063-.059.067-.054.074-.057.077-.059.078.139-.702h-1.111z"
      fill="#fff"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m32.368 23.216h1.092.019l.373-2.203.014-.085.021-.08.017-.082.03-.077.029-.078.039-.074.046-.069.054-.066.062-.059.07-.056.086-.048.092-.043.109-.034.122-.032.13-.019.15-.012h.013l.016.004.017.004h.019l.022.004.015.004.009.004h.005l.005-.016.013-.046.013-.07.023-.084.024-.102.029-.108.034-.109.036-.109.029-.072.038-.074.039-.074.039-.065.035-.059.027-.046.02-.027.008-.013h-.004l-.015-.003-.021-.004-.024-.002h-.03l-.029-.004-.028-.004h-.027l-.119.008-.11.009-.101.021-.093.023-.088.031-.082.04-.075.037-.07.047-.064.049-.063.058-.058.061-.057.063-.059.067-.054.074-.057.077-.059.078.139-.702h-1.111z"
      fill="none"
      stroke="#fff"
      strokeMiterlimit="2.6131"
      strokeWidth=".1102"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m49.383 27.292v-.015l.028-.102.058-.09.063-.076.081-.06.096-.049.1-.027.107-.008.109.008.101.027.093.049.081.06.066.076.054.09.032.102v.015h-.111l-.027-.082-.037-.074-.05-.065-.063-.054-.071-.042-.086-.026-.091-.011-.087.011-.086.026-.074.042-.064.054-.048.065-.037.074-.025.082z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m49.723 27.292v-.134h.213.046l.043.008.032.005.027.015.018.022.016.023.008.024.005.037h-.078v-.019l-.005-.022-.008-.011-.009-.009-.011-.008-.022-.005-.019-.004h-.019-.024-.116v.078z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m49.371 27.376.012-.084h.112v.004l-.009.08z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m49.723 27.376v-.084h.097v.077h.096l.036-.004.027-.005.023-.01.019-.009.015-.009.008-.013.009-.019v-.008h.078l-.005.027-.008.022-.013.024-.012.011z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m50.346 27.376-.005-.08v-.004h.111l.012.084z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m49.371 27.376v.012l.012.112.028.106.058.093.063.082.081.065.096.046.1.033.107.009.109-.009.101-.033.093-.046.081-.065.066-.082.054-.093.032-.106.012-.112v-.012h-.118v.012l-.005.095-.027.084-.037.073-.05.067-.063.054-.071.039-.086.032-.091.005-.087-.005-.086-.032-.074-.039-.064-.054-.048-.067-.037-.073-.025-.084-.009-.095v-.012z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m49.723 27.376v.226h.097v-.194h.096l.115.194h.078l-.115-.194h.027l.027-.008.024-.008.021-.016z"
      fill="#fbb231"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      d="m32.487 20.304-.031-.231-.054-.205-.076-.178-.098-.154-.117-.133-.127-.109-.139-.092-.151-.07-.155-.059-.159-.037-.159-.028-.159-.016-.151-.005-.314.018-.278.046-.253.069-.224.098-.194.116-.17.132-.151.146-.125.156-.108.162-.088.17-.07.168-.058.165-.042.165-.032.149-.022.144-.016.123-.02.317.013.287.042.258.065.229.094.203.113.181.131.158.152.131.162.113.175.098.182.071.19.058.193.049.195.026.192.021.189.004h.127l.121-.004h.104l.101-.004.089-.008.081-.009.078-.008.07-.011.066-.012.062-.017.062-.021.059-.018.057-.025.063-.022.061-.028.062-.031.155-.951-.111.066-.109.063-.111.047-.11.042-.106.033-.108.021-.105.021-.104.017-.105.007-.101.004h-.104l-.101-.004-.101-.007-.101-.012-.098-.013-.1-.016-.077-.018-.079-.024-.076-.036-.071-.043-.069-.046-.063-.058-.058-.059-.054-.064-.047-.067-.041-.073-.032-.07-.026-.073-.017-.073-.008-.074.003-.063.01-.052h2.514l.018-.234.059-.326.027-.294zm-1.049.358h-1.416l.002-.003-.003-.001.011-.056.015-.079.024-.079.027-.077.036-.069.035-.066.041-.063.047-.058.05-.054.051-.046.059-.042.057-.036.066-.03.066-.023.066-.017.069-.012.074-.003.07.007.066.013.062.016.062.023.058.03.058.036.05.038.047.046.039.054.038.058.027.066.028.074.014.077.008.086.005.093z"
      fill="#fff"
    />
  </svg>
));

CCMasterCardIcon.displayName = 'CCMasterCardIcon';
