import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const CCDefaultIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 40.7"
    ref={ref}
    {...props}
  >
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="#F9F9F9"
      d="M2.1 39.8c-.9 0-1.6-.7-1.6-1.6V2.5c0-.9.7-1.6 1.6-1.6h59.7c.9 0 1.6.7 1.6 1.6v35.7c0 .9-.7 1.6-1.6 1.6H2.1z"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="#C6C6C5"
      d="M61.9 1.3c.6 0 1.1.5 1.1 1.1v35.7c0 .6-.5 1.1-1.1 1.1H2.1c-.6 0-1.1-.5-1.1-1.1V2.5c0-.6.5-1.1 1.1-1.1h59.8m0-1.1H2.1C1 .3 0 1.3 0 2.5v35.7c0 1.2 1 2.1 2.1 2.1h59.7c1.2 0 2.1-1 2.1-2.1V2.5c.1-1.2-.9-2.2-2-2.2z"
    />
    <path
      xmlns="http://www.w3.org/2000/svg"
      fill="#4D626E"
      d="M46.7 7.4H17.1c-2 0-3.7 1.7-3.7 3.7v18.5c0 2 1.7 3.7 3.7 3.7h29.6c2 0 3.7-1.7 3.7-3.7V11.1c0-2.1-1.6-3.7-3.7-3.7zm0 22.2H17.1V18.5h29.6v11.1zm0-16.7H17.1V11h29.6v1.9zm-25.9 9.5v1.1H22v-1.1h-1.2zm6.7 2.2v1.1h2.2v-1.1h1.1v-1.1h1.1v-1.1h-2.2v1.1h-1.1v1.1h-1.1zm4.4 1.1v-1.1h-1.1v1.1h1.1zm-5.5 0v-1.1h-2.2v1.1h2.2zm1.1-2.2h1.1v-1.1h-2.2v2.2h1.1v-1.1zm-4.4 1.1h1.1v-1.1h1.1v-1.1h-2.2v1.1H22v1.1h-1.1v1.1h2.2v-1.1z"
    />
  </svg>
));

CCDefaultIcon.displayName = 'CCDefaultIcon';
