import { isNil } from 'remeda';

import { ErrorIcon } from '@/icons';
import { Button } from '@/ui';
import {
  type MinimalModalProps,
  Modal,
  type ModalProps,
} from '@/ui/Modal/modal.component';

export type ErrorModalProps = MinimalModalProps & {
  title?: string;
  description?: string;
  onClose: ModalProps['onClose'];
  onAction?: () => void;
};

export const ErrorModal = ({
  title = 'There was an error...',
  description,
  onClose,
  onAction,
  ...rest
}: ErrorModalProps) => {
  const handleClick = () => {
    if (!isNil(onAction)) {
      onAction();
    } else {
      onClose(false);
    }
  };
  return (
    <Modal onClose={onClose} open {...rest}>
      <div className="flex flex-col items-center justify-center">
        <ErrorIcon className="h-40 w-40 text-red-500" />
        <p className="mt-8 text-4xl font-bold text-red-500">{title}</p>
        {description && <p className="mt-6 text-lg">{description}</p>}
        <Button
          className="mt-12 w-[222px]"
          onClick={handleClick}
          color="default"
        >
          Got it
        </Button>
      </div>
    </Modal>
  );
};
