import { type ModalProps } from '@/ui/Modal/modal.component';
import { ErrorModal } from '@/ui';

export type ErrorModalProps = Omit<ModalProps, 'onClose'> & {
  title?: string;
  description?: string;
  onClose?: ModalProps['onClose'];
};

export const PaymentErrorModal = ({
  title,
  description = "Your payment can't be processed. Please, try again. If the problem persists, please contact our support.",
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClose = () => {},
  ...rest
}: ErrorModalProps) => {
  return (
    <ErrorModal
      {...rest}
      title={title ? title : 'There was an error...'}
      description={description}
      onClose={onClose}
    />
  );
};
