import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const CrossIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    version="1.1"
    viewBox="0 0 36 36"
    className="h-6 w-6"
    ref={ref}
    {...props}
  >
    <g xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        fill="#DF484A"
        d="M18,36C8.1,36,0,27.9,0,18.1C0,8.1,8,0.1,17.8,0c10-0.1,18.1,8,18.2,17.9   C36.1,27.9,27.9,36,18,36z M7.5,26c0,0.5,0.1,0.9,0.3,1.3c0.7,1.2,2.8,1.8,4.1,0.4c2-2,4-4,5.9-5.9c0.1-0.1,0.2-0.1,0.3,0   c0.8,0.8,1.6,1.6,2.4,2.4c1.2,1.2,2.4,2.4,3.6,3.6c0.8,0.8,2.2,1,3.1,0.5c1.2-0.7,1.8-2.8,0.5-4.1c-2-2-4-4-6-6   c-0.1-0.1-0.1-0.2,0-0.3c0.8-0.8,1.6-1.6,2.4-2.4c1.2-1.2,2.4-2.4,3.6-3.6c0.8-0.8,1-2.2,0.4-3.2c-0.7-1.2-2.8-1.8-4.1-0.4   c-2,2-4,4-5.9,5.9c-0.1,0.1-0.2,0.1-0.3,0c-0.8-0.8-1.6-1.6-2.4-2.4c-1.2-1.2-2.4-2.4-3.6-3.6c-0.8-0.8-1.8-1-2.9-0.6   c-1.5,0.5-2.2,2.8-0.8,4.2c2,2,4,4,6,6c0.1,0.1,0.1,0.2,0,0.3c-2,2-4,4-6,6C7.7,24.6,7.5,25.3,7.5,26z"
      />
      <path
        fillRule="evenodd"
        fill="#FFFFFF"
        d="M7.5,26c0-0.7,0.3-1.4,0.8-1.9c2-2,4-4,6-6c0.1-0.1,0.1-0.2,0-0.3c-2-2-4-4-6-6   C6.8,10.4,7.6,8.2,9,7.6c1.1-0.4,2-0.2,2.9,0.6c1.2,1.2,2.4,2.4,3.6,3.6c0.8,0.8,1.6,1.6,2.4,2.4c0.1,0.1,0.2,0.1,0.3,0   c2-2,4-3.9,5.9-5.9c1.4-1.4,3.4-0.8,4.1,0.4c0.6,1,0.4,2.3-0.4,3.2c-1.2,1.2-2.4,2.4-3.6,3.6c-0.8,0.8-1.6,1.6-2.4,2.4   c-0.1,0.1-0.1,0.2,0,0.3c2,2,4,4,6,6c1.4,1.4,0.8,3.4-0.5,4.1c-1,0.6-2.3,0.4-3.1-0.5c-1.2-1.2-2.4-2.4-3.6-3.6   c-0.8-0.8-1.6-1.6-2.4-2.4c-0.1-0.1-0.2-0.1-0.3,0c-2,2-4,3.9-5.9,5.9c-1.4,1.4-3.4,0.8-4.1-0.4C7.6,26.9,7.5,26.5,7.5,26z"
      />
    </g>
  </svg>
));

CrossIcon.displayName = 'CrossIcon';
