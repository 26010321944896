import { forwardRef, type ComponentPropsWithoutRef } from 'react';

export const ChevronDownIcon = forwardRef<
  SVGSVGElement,
  ComponentPropsWithoutRef<'svg'>
>((props, ref) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    viewBox="0 0 24 24"
    ref={ref}
    stroke="currentColor"
    strokeWidth={2}
    fill="none"
    className="h-6 w-6"
    {...props}
  >
    <path strokeLinecap="round" strokeLinejoin="round" d="M19 9l-7 7-7-7" />
  </svg>
));

ChevronDownIcon.displayName = 'ChevronDownIcon';
