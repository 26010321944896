import { Navigate, useLocation } from 'react-router-dom';
import { type ReactNode } from 'react';
import { isNil } from 'remeda';

import { useSubscribedUser } from '@/features/auth/hooks';
import { LEGACY_PRO_PLAN_ID } from '@/features/billing/constants';

type Props = {
  children: ReactNode;
  noLegacyUser?: boolean;
};

export const RequireSubscription = ({
  children,
  noLegacyUser = true,
}: Props) => {
  const location = useLocation();
  const user = useSubscribedUser();

  if (isNil(user.activeSubscription)) {
    return (
      <Navigate to="/billing/pricing" state={{ from: location }} replace />
    );
  }

  if (noLegacyUser && user.activeSubscription.plan.id === LEGACY_PRO_PLAN_ID) {
    return (
      <Navigate to="/billing/pricing" state={{ from: location }} replace />
    );
  }

  return <>{children}</>;
};
