import type { AxiosRequestConfig } from 'axios';
import { isNil } from 'remeda';

import type {
  ExtraCreditsSummary,
  ExtraLoginSeatsSummary,
  GetCountriesData,
  GetPricePayload,
  GetPriceResponse,
  GetProductsData,
  GetSeatPricePayload,
  GetVatInfoPayload,
  GetVatInfoResponse,
  UpgradeCheckoutData,
  UpgradeCheckoutPricesData,
} from '../types/billing.types';
import type { GetCouponPayload, GetPaymentCoupon } from '../types/coupon.types';

import { client, mockClient } from '@/app/api';
import type { BillingPeriodData } from '@/features/billing/types/billing.types';
import { objectToUrlSearchParams } from '@/shared/utils/url-search-params.utils';

export const getBillingPeriodData = () =>
  mockClient.get<BillingPeriodData>('/api/billing-period');

export const getExtraCreditsSummary = (data: { extraCredits?: string }) =>
  mockClient.get<ExtraCreditsSummary>(
    `/api/extra-credits/summary?extraCredits=${objectToUrlSearchParams(data)}`,
  );

export const getExtraLoginSeatsSummary = (data: { extraLoginSeats?: string }) =>
  mockClient.get<ExtraLoginSeatsSummary>(
    `/api/extra-login-seats/summary?${objectToUrlSearchParams(data)}`,
  );

export const getUpgradeCheckoutData = () =>
  mockClient.get<UpgradeCheckoutData>('/api/upgrade-checkout');

export const getUpgradeCheckoutPrice = (data: {
  monitors?: string;
  loginSeat?: string;
  extraCredits?: string;
  billingPeriod?: string;
}) =>
  mockClient.get<UpgradeCheckoutPricesData>(
    `/api/upgrade-checkout-prices?${objectToUrlSearchParams(data)}`,
  );

export const getCountries = () =>
  client.get<GetCountriesData>('/internal/payment/country');

export const validateCoupon = <TErrorData = never>(
  ...args: payload<GetCouponPayload>
) => {
  const [data, config] = args;

  if (isNil(data.couponCode)) {
    Promise.reject(new Error('Coupon code not provided'));
  }

  return client.get<GetPaymentCoupon | TErrorData>(
    `/internal/payment/coupon/${data.couponCode}`,
    config,
  );
};

export const getProducts = () =>
  client.get<GetProductsData>('/internal/payment/product');

type payload<TPayload> = Parameters<
  (data: TPayload, config?: AxiosRequestConfig<TPayload>) => unknown
>;

export const getPrice = (...args: payload<GetPricePayload>) => {
  const [data, config] = args;
  const urlSearchParams = objectToUrlSearchParams(data);

  return client.get<GetPriceResponse>(
    `/internal/payment/price?${urlSearchParams}`,
    config,
  );
};

export const getSeatPrice = (...args: payload<GetSeatPricePayload>) => {
  const [data, config] = args;
  const urlSearchParams = objectToUrlSearchParams(data);

  return client.get<GetPriceResponse>(
    `/internal/payment/seat-price?${urlSearchParams}`,
    config,
  );
};

export const getVatInfo = (...args: payload<GetVatInfoPayload>) => {
  const [data, config] = args;

  const urlSearchParams = objectToUrlSearchParams(data);

  return client.get<GetVatInfoResponse>(
    `/internal/payment/vat-validation?${urlSearchParams}`,
    config,
  );
};
