import type { QueryClient } from '@tanstack/react-query';

import { routerQueryLoaderFn } from '@/shared/utils/query-client.utils';
import type { LoaderStore } from '@/stores';
import { getProductsQuery } from '@/features/billing/api/get-products.query';
import { redirectCancellingCustomers } from '@/shared/utils/redirect-cancelling-customers.util';
import { type SubscribedUser } from '@/features/auth/types';

export const billingPeriodRouteLoader =
  (
    queryClient: QueryClient,
    globalLoaderActions: LoaderStore['actions'],
    user: SubscribedUser,
  ) =>
  async () => {
    globalLoaderActions.show();

    const isRedirecting = redirectCancellingCustomers(user);

    const data = await Promise.all([
      routerQueryLoaderFn(queryClient, getProductsQuery()),
    ]);

    if (!isRedirecting) {
      globalLoaderActions.hide();
    }

    return data;
  };
